import { SupabaseClient } from '@supabase/supabase-js'
import { GoogleDriveFile, GoogleDriveFolder } from '../types/googleDrive.ts'

async function registerGoogleDriveFolder(
    accessToken: string,
    folderId: string,
    onSuccess: () => void,
    onError: (error: Error) => void
) {
    const backendUrl = import.meta.env.EARTHSCALE_BACKEND_URL as string
    const strippedBackendUrl = backendUrl.replace(/\/+$/, '')

    fetch(strippedBackendUrl + '/google_drive/register', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ folder_id: folderId }),
    })
        .then((response) => response.json())
        .then((data) => {
            onSuccess()
        })
        .catch((error) => {
            onError(error)
        })
}

async function fetchGoogleDriveFiles(
    client: SupabaseClient
): Promise<GoogleDriveFile[]> {
    const { data, error } = await client.from('google_drive_files').select('*')
    if (error) {
        throw error
    }
    // We can't handle files without a dataset id for now
    const dbDriveFiles = data.filter((file) => file.dataset_id)

    // Convert to the internal type (avoiding snake case)
    const driveFiles = dbDriveFiles.map<GoogleDriveFile>((file) => {
        return {
            id: file.id,
            name: file.name,
            googleDriveId: file.google_drive_id,
            datasetId: file.dataset_id,
            parentPath: file.parent_path,
            createdAt: new Date(file.created_at),
        }
    })
    return driveFiles
}

// Function to insert a file into the folder structure based on its path
function insertFileIntoFolderStructure(
    folder: GoogleDriveFolder,
    file: GoogleDriveFile,
    pathParts: string[],
    driveFolders: GoogleDriveFolder[]
): void {
    if (pathParts.length === 0) {
        // Base case: add the file to the current folder
        folder.files.push(file)
        return
    }

    const driveFoldersById = driveFolders.reduce((acc, folder) => {
        acc[folder.googleDriveId] = folder
        return acc
    })

    const currentFolderId = pathParts[0]

    // Find or create the subfolder for the current part
    let subfolder = folder.subfolders.find(
        (f) => f.googleDriveId === currentFolderId
    )
    if (!subfolder) {
        subfolder = driveFoldersById[currentFolderId]
        folder.subfolders.push(subfolder)
    }

    // Recursively insert the file into the next level of the folder structure
    insertFileIntoFolderStructure(
        subfolder,
        file,
        pathParts.slice(1),
        driveFolders
    )
}

// Main function to convert the files list into a recursive folder structure
function buildFolderStructure(
    files: GoogleDriveFile[],
    driveFolders: GoogleDriveFolder[]
): GoogleDriveFolder[] {
    const rootFolders: GoogleDriveFolder[] = []

    files.forEach((file) => {
        // Split the parent path to determine the folder hierarchy
        const pathParts = file.parentPath
            .split('/')
            .filter((part) => part !== '')

        // Find or create the root folder for the first part of the path
        const rootFolderId = pathParts[0]
        let rootFolder = rootFolders.find(
            (folder) => folder.googleDriveId == rootFolderId
        )
        const driveFolder = driveFolders.find(
            (folder) => folder.googleDriveId === rootFolderId
        )
        if (!rootFolder) {
            rootFolder = driveFolder
            rootFolders.push(rootFolder)
        }

        // Insert the file into the folder structure starting from the root folder
        insertFileIntoFolderStructure(
            rootFolder,
            file,
            pathParts.slice(1),
            driveFolders
        )
    })

    return rootFolders
}

async function fetchGoogleDriveFolders(
    client: SupabaseClient
): Promise<GoogleDriveFolder[]> {
    const files = await fetchGoogleDriveFiles(client)

    const { data, error } = await client
        .from('google_drive_folders')
        .select('*')
    if (error) {
        throw error
    }

    // Convert from database model to internal model
    const flatFolders = data.map<GoogleDriveFolder>((folder) => {
        return {
            id: folder.id,
            googleDriveId: folder.google_drive_id,
            name: folder.name,
            files: [],
            subfolders: [],
            createdAt: new Date(folder.created_at),
        }
    })

    const folders = buildFolderStructure(files, flatFolders)
    return folders
}

function filterFolderByDatasetIds(
    folder: GoogleDriveFolder,
    datasetIds: string[]
): GoogleDriveFile[] {
    // Returns a new GoogleDriveFolder only containing the files which have a datasetId
    // present in the datasetIds array
    // Empty subfolders are removed
    const files = folder.files.filter((file) =>
        datasetIds.includes(file.datasetId)
    )
    const subfolders = folder.subfolders.map((subfolder) =>
        filterFolderByDatasetIds(subfolder, datasetIds)
    )
    return {
        ...folder,
        files,
        subfolders: subfolders.filter(
            (subfolder) =>
                subfolder.files.length > 0 || subfolder.subfolders.length > 0
        ),
    }
}

export {
    fetchGoogleDriveFolders,
    registerGoogleDriveFolder,
    filterFolderByDatasetIds,
}
