const CacheManager = {
    // Current app version - update this when deploying new versions
    VERSION_KEY: 'app_version',
    PREFIX: 'earthscale_',

    setVersion(version: string) {
        localStorage.setItem(this.VERSION_KEY, version)
    },

    // Initialize the cache manager
    init(version: string) {
        const storedVersion = localStorage.getItem(this.VERSION_KEY)

        // If no version is stored or version is different, clear cache
        if (!storedVersion || storedVersion !== version) {
            this.invalidateCache()
        }
    },

    // Clear all localStorage items except the version
    invalidateCache() {
        const keysToPreserve = [this.VERSION_KEY]

        // Get all keys in localStorage that start with our prefix
        const keys = Object.keys(localStorage).filter((key) =>
            key.startsWith(this.PREFIX)
        )

        // Remove all items except preserved keys
        keys.forEach((key) => {
            if (!keysToPreserve.includes(key)) {
                localStorage.removeItem(key)
            }
        })

        // Update stored version
        localStorage.setItem(this.VERSION_KEY, this.APP_VERSION)
    },

    // Utility method to set items with version checking
    setItem(key, value) {
        localStorage.setItem(this.PREFIX + key, value)
    },

    // Utility method to get items with version checking
    // We assume the version is always correct since we've already checked it in init()
    getItem(key) {
        return localStorage.getItem(this.PREFIX + key)
    },
}

export default CacheManager
