import { IconButton, Tooltip } from '@mui/material'
import React from 'react'
import DatasetCardStyle from './DatasetCard.module.css'
import Box from '@mui/material/Box'
import { useMapContext } from '../../context/map/mapContext.ts'
import ColorLensOutlinedIcon from '@mui/icons-material/ColorLensOutlined'

interface EditVizButtonProps {
    datasetVersionId: string
}

function EditVizButton({ datasetVersionId }: EditVizButtonProps) {
    const { dispatch } = useMapContext()

    return (
        <Box>
            <Tooltip title="Edit visualization">
                <IconButton
                    onClick={(e: React.MouseEvent) => {
                        e.stopPropagation()
                        dispatch({
                            type: 'TOGGLE_DATASET_EDITING',
                            datasetVersionId: datasetVersionId,
                        })
                    }}
                >
                    <ColorLensOutlinedIcon
                        className={DatasetCardStyle.hoverIcon}
                    />
                </IconButton>
            </Tooltip>
        </Box>
    )
}

export default EditVizButton
