import * as React from 'react'
import { useState } from 'react'
import ReactDOM from 'react-dom'
import Stack from '@mui/material/Stack'
import SidebarStyle from './Sidebar.module.css'
import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    IconButton,
    Paper,
    Snackbar,
    TextField,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material'
import { Dataset } from '../../types/dataset.js'
import VizParamsEditor from '../VizParamsEditor/VizParamsEditor'
import MapBox from './MapBox.js'
import { useMapContext } from '../../context/map/mapContext.js'
import Header from './Header.js'
import DatasetList from '../DatasetList/DatasetList.js'
import SearchBar from '../SearchBar/SearchBar.js'
import { Add, Close, CenterFocusStrong } from '@mui/icons-material'
import {
    addLocalDataset,
    convertLocalDatasetToDataset,
} from '../../api/localDataset.js'
import AddDatasetStepper from '../AddDatasetStepper/AddDatasetStepper.tsx'
import { MapRef, useMap } from 'react-map-gl'
import { boundsStringToArray, filterDatasetsInView } from '../../utils'

type SidebarProps = {
    flyToDatasetBounds: (dataset: Dataset) => void
    maxHeight: string | null
    setCatalogOpen: (value: boolean) => void
    mapRef: React.RefObject<MapRef>
    filterDatasetsInView?: (
        bounds: [number, number, number, number]
    ) => Dataset[]
}

function Sidebar({
    flyToDatasetBounds,
    maxHeight,
    setCatalogOpen,
    mapRef,
}: SidebarProps) {
    const {
        state,
        dispatch,
        removeDatasetFromMap,
        addDatasetToMap,
        lastDatasetRef,
        changeMap,
    } = useMapContext()
    const [searchTerm, setSearchTerm] = useState('')
    const theme = useTheme()
    const [isDragOver, setIsDragOver] = useState(false)
    const [error, setError] = useState<string>(null)
    const [pastedDatasetUrl, setPastedDatasetUrl] = useState<string>('')

    const [datasetAddDialogOpen, setDatasetAddDialogOpen] =
        React.useState(false)
    const [filterToView, setFilterToView] = useState(false)

    let datasets = state.current.datasets
    const searchShouldShow = datasets.length > 1
    if (searchShouldShow && searchTerm != '') {
        datasets = datasets.filter((dataset) =>
            dataset.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
    }
    if (searchShouldShow) {
        if (searchTerm !== '') {
            datasets = datasets.filter((dataset) =>
                dataset.name.toLowerCase().includes(searchTerm.toLowerCase())
            )
        }
    }

    // Filter datasets in view
    if (filterToView && mapRef.current) {
        const bounds = mapRef.current.getBounds()
        const viewportBounds: [number, number, number, number] = [
            bounds.getWest(),
            bounds.getSouth(),
            bounds.getEast(),
            bounds.getNorth(),
        ]
        datasets = filterDatasetsInView(datasets, viewportBounds)
    }

    // Expand the dataset list if the map is empty
    React.useEffect(() => {
        if (state.current && state.current.datasets.length === 0) {
            setCatalogOpen(true)
        }
    }, [state.current, setCatalogOpen])

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault()
    }

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault()
        const file = event.dataTransfer.files[0]
        if (
            file &&
            (file.type === 'application/json' ||
                file.type === 'application/geo+json')
        ) {
            const reader = new FileReader()
            reader.onload = (e) => {
                try {
                    if (file.size > 10 * 1024 * 1024) {
                        const fileSizeInMb = file.size / (1024 * 1024)
                        // Throw error
                        throw new Error(
                            `File ${file.name} too large, the maximum file size we're supporting is 10mb. The file has ${fileSizeInMb.toFixed(2)}mb.`
                        )
                    }

                    const filename = file.name
                    const content = JSON.parse(e.target.result as string)
                    const mapId = state.current.metadata.id
                    const localDataset = {
                        mapId: mapId,
                        filename: filename,
                        createdAt: new Date(file.lastModified).toISOString(),
                        geometry: content,
                    }
                    try {
                        // Make sure we can convert the local dataset to a dataset
                        // This will raise an error if there is a conversion.
                        convertLocalDatasetToDataset(localDataset)
                    } catch (e) {
                        throw new Error(
                            `The file might not be a correct geojson.`
                        )
                    }
                    addLocalDataset(localDataset)
                    // Calling change map here causes a re-load from the local storage
                    // immediately
                    changeMap(mapId)
                } catch (error) {
                    const errorMessage = `Failed to add file ${file.name}, error: ${error.message}`
                    setError(errorMessage)
                }
            }
            reader.readAsText(file)
        } else {
            setError(
                'Only GeoJSON files are supported at the moment when adding ' +
                    'datasets locally. Please reach  out in case you need support for ' +
                    'other file types.'
            )
        }
        setIsDragOver(false)
    }

    const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault()
        setIsDragOver(true)
    }

    const handleDragLeave = () => {
        setIsDragOver(false)
    }

    const datasetBeingEdited = datasets.find((dataset) => dataset.isBeingEdited)

    React.useEffect(() => {
        const datasetBeingEdited = datasets.find((d) => d.isBeingEdited)
        if (datasetBeingEdited) {
            const handleKeyDown = (event: KeyboardEvent) => {
                if (event.key === 'Escape' && datasetBeingEdited) {
                    dispatch({
                        type: 'TOGGLE_DATASET_EDITING',
                        datasetVersionId: datasetBeingEdited.id,
                    })
                }
            }
            window.addEventListener('keydown', handleKeyDown)
            return () => window.removeEventListener('keydown', handleKeyDown)
        }
    }, [datasets])

    // Values empirically determined
    const datasetListMaxHeight = maxHeight
        ? `calc(${maxHeight} - 295px)`
        : `calc(100vh - 421px)`

    const addButtonVariant =
        state.current.datasets.length > 0 ? 'outlined' : 'contained'

    React.useEffect(() => {
        window.addEventListener('dragenter', handleDragEnter)
        return () => window.removeEventListener('dragenter', handleDragEnter)
    }, [])

    React.useEffect(() => {
        window.addEventListener('dragleave', handleDragLeave)
        return () => window.removeEventListener('dragleave', handleDragLeave)
    }, [])

    React.useEffect(() => {
        window.addEventListener('drop', handleDrop)
        return () => window.removeEventListener('drop', handleDrop)
    }, [])

    React.useEffect(() => {
        window.addEventListener('dragover', handleDragOver)
        return () => window.removeEventListener('dragover', handleDragOver)
    }, [])

    const specialKeySymbol = navigator.platform.includes('Mac') ? '⌘' : 'Ctrl'
    React.useEffect(() => {
        const handlePaste = (event: ClipboardEvent) => {
            event.clipboardData.items[0].getAsString((text) => {
                setPastedDatasetUrl(text.trim())
                setDatasetAddDialogOpen(true)
            })
        }
        window.addEventListener('paste', handlePaste)
        return () => window.removeEventListener('paste', handlePaste)
    }, [])

    const handleDatasetAddDialogClose = () => {
        setDatasetAddDialogOpen(false)
        setPastedDatasetUrl('')
    }

    return (
        <>
            {ReactDOM.createPortal(
                <Box
                    className={SidebarStyle.dragAndDropArea}
                    sx={{
                        backgroundColor: isDragOver
                            ? 'rgb(255, 255, 255, 0.2)'
                            : 'transparent',
                    }}
                    onDrop={handleDrop}
                />,
                document.body
            )}
            <Dialog
                open={datasetAddDialogOpen}
                onClose={handleDatasetAddDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={'md'}
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">
                    <Stack direction={'row'}>
                        <>Add new Dataset</>
                        <Box sx={{ flexGrow: '1' }} />
                        <IconButton onClick={handleDatasetAddDialogClose}>
                            <Close />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <AddDatasetStepper
                        initialUrl={pastedDatasetUrl}
                        onClose={handleDatasetAddDialogClose}
                    />
                </DialogContent>
            </Dialog>
            <Paper
                className={SidebarStyle.sidebar}
                sx={{
                    maxHeight: maxHeight,
                }}
            >
                <Stack direction="column" spacing={1}>
                    <Header />
                    <Divider />
                    <MapBox />
                    {searchShouldShow && (
                        <Box sx={{ padding: '0px 8px' }}>
                            <Stack direction="row" spacing={1}>
                                <SearchBar
                                    searchTerm={searchTerm}
                                    onSearchChange={setSearchTerm}
                                />
                                <Tooltip
                                    title={
                                        filterToView
                                            ? 'Toggle to show all datasets'
                                            : 'Toggle to only show datasets in current view'
                                    }
                                >
                                    <IconButton
                                        onClick={() =>
                                            setFilterToView(!filterToView)
                                        }
                                        color={
                                            filterToView
                                                ? 'secondary'
                                                : 'primary'
                                        }
                                        size="small"
                                    >
                                        <CenterFocusStrong />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        </Box>
                    )}
                    <Divider />
                    <Box
                        sx={{
                            maxHeight: datasetListMaxHeight,
                            overflowY: 'auto',
                        }}
                    >
                        {datasetBeingEdited && (
                            <VizParamsEditor
                                dataset={datasetBeingEdited}
                                flyToDatasetBounds={flyToDatasetBounds}
                            />
                        )}
                        {!datasetBeingEdited &&
                            state.current.datasets.length > 0 && (
                                <>
                                    <DatasetList
                                        datasets={datasets}
                                        flyToDatasetBounds={flyToDatasetBounds}
                                        showVizSettings={true}
                                        showSelector={false}
                                        showRemoveFromMap={true}
                                        showVisibility={true}
                                        disableNonActive={true}
                                        reorderable={true}
                                    />
                                    <div ref={lastDatasetRef} />
                                </>
                            )}
                    </Box>
                    <Stack
                        variant={'column'}
                        spacing={1}
                        className={SidebarStyle.addButton}
                    >
                        <Button
                            variant={addButtonVariant}
                            width={'100%'}
                            fullWidth
                            onClick={() => setDatasetAddDialogOpen(true)}
                        >
                            Add Dataset
                        </Button>
                        <Box className={SidebarStyle.addHelpText}>
                            <Typography variant={'body2'}>
                                Try {specialKeySymbol}+V or dragging a file onto
                                the map
                            </Typography>
                        </Box>
                    </Stack>
                </Stack>
                <Snackbar
                    open={error !== null}
                    autoHideDuration={5000}
                    onClose={() => setError(null)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    sx={{ width: '400px' }}
                >
                    <Alert
                        onClose={() => setError(null)}
                        severity="error"
                        variant="filled"
                        sx={{ width: '100%' }}
                        key={error}
                    >
                        {error}
                    </Alert>
                </Snackbar>
            </Paper>
        </>
    )
}

export default Sidebar
