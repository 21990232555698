import * as React from 'react'
import DatasetDetailsStyle from './DatasetDetails.module.css'
import {
    Alert,
    Divider,
    Icon,
    IconButton,
    Paper,
    Snackbar,
    Tooltip,
    Typography,
    useTheme,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Link,
    TableHead,
} from '@mui/material'
import { useMapContext } from '../../context/map/mapContext'
import Box from '@mui/material/Box'
import { Close, ContentCopy } from '@mui/icons-material'
import { boundsStringToArray } from '../../utils'
import { useEffect, useState } from 'react'
import { createTileServerURL } from '../../api/tiler'
import hljs from 'highlight.js'
import { useSupabaseContext } from '../../context/supabase/supabaseContext'
import { Dataset } from 'types/dataset'
import ZoomButton from '../../components/DatasetCard/ZoomButton'

function formatDate(date: string) {
    return new Date(date).toLocaleString()
}

function capitalize(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1)
}

function formatExtent(extent: string): string {
    const extentArray = boundsStringToArray(extent)
    const rounded = extentArray.map((value) => value.toFixed(2))
    return '[' + rounded.map((number) => number.toString()).join(', ') + ']'
}

function formatExtentAsWKT(extent: string): string {
    const extentArray = boundsStringToArray(extent)
    return `POLYGON((${extentArray[0]} ${extentArray[1]}, ${extentArray[2]} ${extentArray[1]}, ${extentArray[2]} ${extentArray[3]}, ${extentArray[0]} ${extentArray[3]}, ${extentArray[0]} ${extentArray[1]}))`
}

function Header({
    datasetName,
    datasetType,
    onClose,
    zoomButton,
}: {
    datasetName: string
    datasetType: string
    onClose: () => void
    zoomButton: React.ReactNode
}) {
    return (
        <Box className={DatasetDetailsStyle.header}>
            <Box>
                <Typography variant="h5">{datasetName}</Typography>
                <Typography variant="body2">
                    {capitalize(datasetType) + ' Dataset'}
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {zoomButton}

                <IconButton onClick={onClose}>
                    <Close fontSize="medium" />
                </IconButton>
            </Box>
        </Box>
    )
}

function Footer({
    createdAt,
    updatedAt,
}: {
    createdAt: string
    updatedAt: string
}) {
    return (
        <Box className={DatasetDetailsStyle.footer}>
            <Typography variant="body2">
                Created: {formatDate(createdAt)}
            </Typography>
            <Typography variant="body2">
                Last Updated: {formatDate(updatedAt)}
            </Typography>
        </Box>
    )
}

function ExtentLine({
    extent,
}: {
    extent: string
    zoomButton: React.ReactNode
}) {
    const theme = useTheme()
    const [open, setOpen] = useState(false)
    const extentString = formatExtent(extent)

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return
        }
        setOpen(false)
    }

    return (
        <Box className={DatasetDetailsStyle.extentLine}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1" sx={{ paddingRight: '8px' }}>
                    Extent:
                </Typography>
                <Typography variant="body2">{extentString}</Typography>
            </Box>

            <Tooltip title={'Copy to clipboard (as WKT)'}>
                <IconButton
                    onClick={() => {
                        navigator.clipboard.writeText(formatExtentAsWKT(extent))
                        setOpen(true)
                    }}
                >
                    <ContentCopy />
                </IconButton>
            </Tooltip>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}
                sx={{ marginBottom: '20px' }}
            >
                <Alert
                    onClose={handleClose}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%', fontColor: 'white' }}
                >
                    Successfully copied extent as WKT to clipboard
                </Alert>
            </Snackbar>
        </Box>
    )
}

function TileServerLine({ dataset }: { dataset: Dataset }) {
    const [open, setOpen] = useState(false)
    const { user } = useSupabaseContext()

    // There might not be API keys for a user
    if (user.api_keys.length === 0) {
        return null
    }

    const tileUrl = createTileServerURL(dataset, user)

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return
        }
        setOpen(false)
    }

    return (
        <Box className={DatasetDetailsStyle.extentLine}>
            <Box sx={{ display: 'flex', alignItems: 'center', minWidth: 0 }}>
                <Typography
                    variant="body1"
                    sx={{ paddingRight: '8px', flexShrink: 0 }}
                >
                    Tile Server URL:
                </Typography>
                <Typography
                    variant="body2"
                    sx={{
                        overflowX: 'auto',
                        whiteSpace: 'nowrap',
                        flexShrink: 1,
                        minWidth: 0,
                        '&::-webkit-scrollbar': {
                            height: '8px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            // backgroundColor: 'rgba(0,0,0,0.2)',
                            borderRadius: '4px',
                        },
                    }}
                >
                    {tileUrl}
                </Typography>
            </Box>
            <Tooltip title={'Copy URL to clipboard'}>
                <IconButton
                    onClick={() => {
                        navigator.clipboard.writeText(tileUrl)
                        setOpen(true)
                    }}
                >
                    <ContentCopy />
                </IconButton>
            </Tooltip>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}
                sx={{ marginBottom: '20px' }}
            >
                <Alert
                    onClose={handleClose}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%', fontColor: 'white' }}
                >
                    Successfully copied tile server URL to clipboard
                </Alert>
            </Snackbar>
        </Box>
    )
}

const markdown = `
from earthscale.datasets.raster import ZarrDataset

dataset = ZarrDataset(
    store="gs://earthscale-demo/sample/hls_tanzania.zarr",
)
`

function CodeBlock() {
    useEffect(() => {
        hljs.highlightAll()
    }, [])
    const [open, setOpen] = useState(false)

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return
        }
        setOpen(false)
    }
    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <Typography variant="body1">Definition:</Typography>
                <Tooltip title={'Copy snippet to clipboard'}>
                    <IconButton
                        onClick={() => {
                            navigator.clipboard.writeText('')
                            setOpen(true)
                        }}
                    >
                        <ContentCopy />
                    </IconButton>
                </Tooltip>
            </Box>
            <pre>
                <code className={'python'}>{markdown}</code>
            </pre>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}
                sx={{ marginBottom: '20px' }}
            >
                <Alert
                    onClose={handleClose}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%', fontColor: 'white' }}
                >
                    Successfully copied code snippet to clipboard
                </Alert>
            </Snackbar>
        </Box>
    )
}

function AttributesTable({ dataset }: { dataset: Dataset }) {
    if (!dataset.attributes || Object.keys(dataset.attributes).length === 0) {
        return null
    }

    return (
        <Box>
            <Typography variant="body1" sx={{ marginBottom: 1 }}>
                Attributes:
            </Typography>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell
                                sx={{ fontWeight: 'bold', width: '30%' }}
                            >
                                Key
                            </TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>
                                Value
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.entries(dataset.attributes).map(
                            ([key, value]) => (
                                <TableRow
                                    key={key}
                                    sx={{
                                        borderTop:
                                            '1px solid rgba(224, 224, 224, 1)',
                                    }}
                                >
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{
                                            width: '30%',
                                        }}
                                    >
                                        {key}
                                    </TableCell>
                                    <TableCell>
                                        {typeof value === 'object' ? (
                                            JSON.stringify(value)
                                        ) : typeof value === 'string' &&
                                          value.match(/^https?:\/\//) ? (
                                            <Link
                                                href={value}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {value}
                                            </Link>
                                        ) : (
                                            String(value)
                                        )}
                                    </TableCell>
                                </TableRow>
                            )
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

function DatasetDetails({
    setActiveDetailPanel,
    flyToDatasetBounds,
}: {
    setActiveDetailPanel: (
        panel: 'dataset' | 'pixel' | 'comment' | 'newThread'
    ) => void
    flyToDatasetBounds: (dataset: Dataset) => void
}) {
    const { state, dispatch } = useMapContext()

    if (!state.selectedDatasetVersionId) {
        return null
    }

    const allDatasets = [...state.current.datasets, ...state.catalog]
    const dataset = allDatasets.find(
        (dataset) => dataset.id === state.selectedDatasetVersionId
    )
    if (!dataset) {
        return null
    }

    const handleClose = () => {
        setActiveDetailPanel(null)
        dispatch({
            type: 'SELECT_DATASET',
            datasetVersionId: null,
        })
    }

    const zoomButton = (
        <ZoomButton flyToDatasetBounds={flyToDatasetBounds} dataset={dataset} />
    )

    return (
        <Paper className={DatasetDetailsStyle.outerBox}>
            <Header
                datasetName={dataset.name}
                datasetType={dataset.type}
                onClose={handleClose}
                zoomButton={zoomButton}
            />

            <Divider />
            {dataset.status === 'ready' && (
                <>
                    <Box className={DatasetDetailsStyle.details}>
                        {/*Commented as we don't have a generic way of showing this yet*/}
                        {/*<CodeBlock/>*/}
                        <ExtentLine
                            extent={dataset.extent}
                            zoomButton={zoomButton}
                        />

                        {/*local datasets don't have a tileserver URL*/}
                        {dataset.source != 'local' && (
                            <TileServerLine dataset={dataset} />
                        )}
                        <AttributesTable dataset={dataset} />
                    </Box>
                    <Footer
                        createdAt={dataset.createdAt}
                        updatedAt={dataset.updatedAt}
                    />
                </>
            )}
            {dataset.status !== 'ready' &&
                dataset.error &&
                dataset.traceback && (
                    <>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Typography variant="h6" color="error">
                                Error
                            </Typography>
                        </Box>
                        <Typography variant="body2" color="textSecondary">
                            {dataset.error}
                        </Typography>
                        <Typography
                            variant="h6"
                            color="error"
                            style={{ marginTop: '10px' }}
                        >
                            Traceback
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            {dataset.traceback}
                        </Typography>
                    </>
                )}
        </Paper>
    )
}

export default DatasetDetails
