import * as React from 'react'
import { Box, Button, Stack, TextField, Typography } from '@mui/material'

type AddStepProps = {
    url: string
    onUrlChange: (url: string) => void
    onNextClick: () => void
}

function AddStep({ url, onUrlChange, onNextClick }: AddStepProps) {
    const [error, setError] = React.useState<string | null>(null)

    const handleNextClick = () => {
        if (url === '') {
            setError('Please provide a URL')
            return
        }
        onNextClick()
    }

    return (
        <Stack direction={'column'} spacing={2}>
            <Box sx={{ p: '10px' }}>
                <TextField
                    autoFocus
                    margin="dense"
                    id="dataset-url"
                    label="Dataset URL"
                    type="url"
                    fullWidth
                    variant="standard"
                    value={url}
                    onChange={(e) => onUrlChange(e.target.value)}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            handleNextClick()
                        }
                    }}
                />
                {error && <Typography color={'error'}>{error}</Typography>}
            </Box>
            <Stack direction={'row'} spacing={2} sx={{ pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleNextClick}>Next</Button>
            </Stack>
        </Stack>
    )
}

export default AddStep
