import { ChatRequest, ChatResponse } from '../types/chat'

async function chat(
    accessToken: string,
    request: ChatRequest
): Promise<ChatResponse> {
    const backendUrl = import.meta.env.EARTHSCALE_BACKEND_URL as string
    const strippedBackendUrl = backendUrl.replace(/\/+$/, '')

    const response = await fetch(`${strippedBackendUrl}/chat`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
            message: request.message,
            chat_id: request.chatId,
            added_dataset_ids: request.addedDatasetIds,
            upvoted_dataset_ids: request.upvotedDatasetIds,
            downvoted_dataset_ids: request.downvotedDatasetIds,
        }),
    })

    if (!response.ok) {
        throw new Error(`Chat request failed: ${response.statusText}`)
    }

    const data = await response.json()
    return {
        datasetIds: data.dataset_ids,
        explanations: data.explanations,
        responseText: data.response_text,
    }
}

export { chat }
