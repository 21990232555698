import React, { useState } from 'react'
import { Button } from '@mui/material'
import { feedbackIntegration } from '@sentry/react'
import { Campaign } from '@mui/icons-material'

const FeedbackButton: React.FC = () => {
    React.useEffect(() => {
        const feedback = feedbackIntegration({
            // Disable the injection of the default widget
            autoInject: false,
        })
        const feedbackButton = document.querySelector('#sentry-feedback-button')
        if (feedbackButton) {
            feedback.attachTo(feedbackButton, { showBranding: false })
        }
        return feedback.remove
    }, [])

    return (
        <Button
            id="sentry-feedback-button"
            variant="outlined"
            size="small"
            startIcon={<Campaign />}
            sx={{
                borderRadius: '20px',
                backgroundColor: 'var(--panel-background)',
                color: 'white',
                borderColor: 'rgba(230, 246, 231, 0.5)',
                '&:hover': {
                    borderRadius: 'var(--borderRadius, 20px)',
                    border: '1px solid rgba(230, 246, 231, 0.65)',
                    background: 'rgba(39, 46, 45, 0.7)',
                    backdropFilter: 'blur(8px)',
                },
                padding: '8px 16px',
                transition: 'all 0.3s ease',
            }}
        >
            REPORT BUG
        </Button>
    )
}

export default React.memo(FeedbackButton, () => true)
