import React from 'react'
import { FormControl, Radio } from '@mui/material'
import {
    Stepper,
    Step,
    StepLabel,
    Button,
    Typography,
    Box,
    TextField,
    CircularProgress,
    IconButton,
    Stack,
    FormLabel,
    RadioGroup,
    FormControlLabel,
} from '@mui/material'
import { CopyAll } from '@mui/icons-material'
import AddStep from './AddStep.tsx'
import AccessStep from './AccessStep.tsx'
import MetadataStep from './MetadataStep.tsx'

type Step = 'URL' | 'Access' | 'Metadata'

const steps: Step[] = ['URL', 'Access', 'Metadata']

function getStepComponent(
    step: Step,
    url: string,
    onUrlChange: (url: string) => void,
    handleNext: () => void,
    handleBack: () => void,
    handleClose: () => void
) {
    switch (step) {
        case 'URL':
            return (
                <AddStep
                    url={url}
                    onUrlChange={onUrlChange}
                    onNextClick={handleNext}
                />
            )
        case 'Access':
            return (
                <AccessStep
                    url={url}
                    onNextClick={handleNext}
                    onBackClick={handleBack}
                />
            )
        case 'Metadata':
            return (
                <MetadataStep
                    url={url}
                    onBackClick={handleBack}
                    onClose={handleClose}
                />
            )
        default:
            return null
    }
}

type AddDatasetStepperProps = {
    initialUrl: string
    onClose: () => void
}

const AddDatasetStepper: React.FC<AddDatasetStepperProps> = ({
    initialUrl,
    onClose,
}) => {
    const initialStep = initialUrl ? 1 : 0
    const [activeStep, setActiveStep] = React.useState(initialStep)
    const [url, setUrl] = React.useState<string>(initialUrl)

    const isStepOptional = (step: number) => {
        return step === 1
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const labelProps: {
                        optional?: React.ReactNode
                    } = {}
                    if (isStepOptional(index)) {
                        labelProps.optional = (
                            <Typography variant="caption">Optional</Typography>
                        )
                    }
                    return (
                        <Step key={label}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    )
                })}
            </Stepper>
            <Box sx={{ mt: 2, mb: 1, width: '100%' }}>
                {getStepComponent(
                    steps[activeStep],
                    url,
                    setUrl,
                    () => {
                        setActiveStep(
                            Math.min(activeStep + 1, steps.length - 1)
                        )
                    },
                    () => {
                        // Skip access step if we're on the last step
                        setActiveStep(0)
                    },
                    onClose
                )}
            </Box>
        </Box>
    )
}

export default AddDatasetStepper
