/* Map layer displaying all visible dataset. */
import React from 'react'
import { Layer, Source } from 'react-map-gl'
import { MapRef } from 'react-map-gl/maplibre'
import { Dataset } from '../../types/dataset.ts'
import { boundsStringToArray } from '../../utils'
import { VectorVizParams } from '../../types/viz.ts'
import { createTileServerURL } from '../../api/tiler'
import { Tile } from './MapView'
import { useMapContext } from '../../context/map/mapContext.ts'
import { useTheme } from '@mui/material'
import { loadLocalDatasets } from '../../api/localDataset.ts'
import CommentThreadLayer from '../../components/CommentThreadLayer/CommentThreadLayer'

type DatasetLayerProps = {
    dataset: Dataset
}

type VisualizationsLayerProps = {
    currentZoom: number
    mapRef: React.RefObject<MapRef>
    tilesBeingLoaded: Tile[]
}

function boundsToPolygon(
    bounds: [number, number, number, number]
): number[][][] {
    return [
        [
            [bounds[0], bounds[1]],
            [bounds[0], bounds[3]],
            [bounds[2], bounds[3]],
            [bounds[2], bounds[1]],
            [bounds[0], bounds[1]],
        ],
    ]
}

function layerID(dataset: Dataset) {
    if (dataset.type === 'vector') {
        const mode = (dataset.vizParams as VectorVizParams).mode
        return 'dataset-' + dataset.id + '-vector-layer' + '-' + mode
    } else if (dataset.type === 'raster') {
        return 'dataset-' + dataset.id + '-raster-layer'
    }
}

const TILES_BEING_LOADED_LAYER_ID = 'tiles-being-loaded-layer'

function VectorDatasetLayer({ dataset }: DatasetLayerProps) {
    if (!dataset.vizParams) {
        return
    }
    // TODO: update to use layer 'line' instead of 'outline'
    const vizParams: VectorVizParams = dataset.vizParams as VectorVizParams
    const layerType = vizParams.mode === 'outline' ? 'line' : 'fill'
    // TODO: units are in pixels: clarify this
    const mode = vizParams.mode
    const lineWidth = mode === 'outline' ? vizParams.width : 0
    let paintProps = null
    if (vizParams.mode === 'outline') {
        paintProps = {
            'line-color': vizParams.color,
            'line-width': lineWidth,
            'line-opacity': 1.0,
        }
    } else if (vizParams.mode === 'fill') {
        paintProps = {
            'fill-color': vizParams.color,
            'fill-opacity': 1.0,
        }
    }

    if (dataset.source === 'local') {
        // The id of the local dataset is the mapId + filename
        const localDataset = loadLocalDatasets().find((localDataset) => {
            return dataset.id === localDataset.mapId + localDataset.filename
        })
        if (!localDataset) {
            console.error('Local dataset not found:', dataset.id)
            return null
        }

        return (
            <Source
                id={dataset.id + '-vector-source' + '-' + mode}
                type="geojson"
                data={localDataset.geometry}
                key={dataset.id + '-vector-source' + '-' + mode}
            >
                <Layer
                    id={layerID(dataset)}
                    type={layerType}
                    paint={paintProps}
                />
            </Source>
        )
    }

    return (
        <Source
            id={dataset.id + '-vector-source' + '-' + mode}
            type="vector"
            tiles={[createTileServerURL(dataset)]}
            bounds={boundsStringToArray(dataset.extent)}
            key={dataset.id + '-vector-source' + '-' + mode}
            minzoom={dataset.minZoom || 0}
            {...(dataset.maxZoom ? { maxzoom: dataset.maxZoom } : {})}
        >
            <Layer
                id={layerID(dataset)}
                type={layerType}
                source-layer="default"
                paint={paintProps}
            />
        </Source>
    )
}

function RasterDatasetLayer({ dataset }: DatasetLayerProps) {
    const tileServerURL = createTileServerURL(dataset)

    return (
        <Source
            id={dataset.id + '-raster-source'}
            type="raster"
            tiles={[tileServerURL]}
            bounds={boundsStringToArray(dataset.extent)}
            key={dataset.id + '-raster-source'}
            minzoom={dataset.minZoom || 0}
            {...(dataset.maxZoom ? { maxzoom: dataset.maxZoom } : {})}
        >
            <Layer
                id={layerID(dataset)}
                type="raster"
                paint={{ 'raster-resampling': 'nearest' }}
            />
        </Source>
    )
}

function BoundsLayer({
    dataset,
    hovered,
}: {
    dataset: Dataset
    hovered: boolean
}) {
    const theme = useTheme()

    let lineColor = '#888'
    let lineWidth = 1
    if (hovered) {
        lineColor = theme.palette.primary.main
        lineWidth = 3
    }

    if (!dataset.extent) {
        return null
    }

    return (
        <React.Fragment key={dataset.id + '-bounds-fragment'}>
            <Source
                id={dataset.id + '-bounds-source'}
                type="geojson"
                data={{
                    type: 'Feature',
                    properties: {},
                    geometry: {
                        type: 'Polygon',
                        coordinates: boundsToPolygon(
                            boundsStringToArray(dataset.extent)
                        ),
                    },
                }}
            >
                <Layer
                    id={dataset.id + '-bounds-layer'}
                    type="line"
                    paint={{
                        'line-color': lineColor,
                        'line-opacity': 1.0,
                        'line-width': lineWidth,
                    }}
                />
            </Source>
        </React.Fragment>
    )
}

/* From: https://gis.stackexchange.com/questions/390199/getting-latlngbounds-for-tile-based-on-its-tile-coordinate-using-leaflet */
function getLatLngForTile(
    xtile: number,
    ytile: number,
    zoom: number
): [number, number] {
    let n = Math.pow(2.0, zoom)
    let lng = (xtile / n) * 360.0 - 180.0
    let lat_rad = Math.atan(Math.sinh(Math.PI * (1 - (2.0 * ytile) / n)))
    let lat = (180.0 * lat_rad) / Math.PI
    return [lat, lng]
}

function getBoundsForTile(
    xtile: number,
    ytile: number,
    zoom: number
): [number, number, number, number] {
    const northWest = getLatLngForTile(xtile, ytile, zoom)
    const southEast = getLatLngForTile(xtile + 1, ytile + 1, zoom)
    return [northWest[1], northWest[0], southEast[1], southEast[0]]
}

function TileLoadingLayer({ tilesBeingLoaded }: { tilesBeingLoaded: Tile[] }) {
    return (
        <Source
            id="tiles-being-loaded-source"
            type="geojson"
            data={{
                type: 'FeatureCollection',
                features: tilesBeingLoaded.map((tile) => ({
                    type: 'Feature',
                    geometry: {
                        type: 'Polygon',
                        coordinates: boundsToPolygon(
                            getBoundsForTile(tile.x, tile.y, tile.z)
                        ),
                    },
                })),
            }}
        >
            <Layer
                id={`${TILES_BEING_LOADED_LAYER_ID}-fill`}
                type="fill"
                paint={{
                    'fill-color': '#B0C4DE', // Light Steel Blue (pale blue gray)
                    'fill-opacity': 0.35,
                }}
            />
            <Layer
                id={`${TILES_BEING_LOADED_LAYER_ID}-outline`}
                type="line"
                paint={{
                    'line-color': '#000000', // Black for the outline
                    'line-width': 2,
                    'line-opacity': 0.2,
                }}
            />
        </Source>
    )
}

function VisualizationsLayer({
    currentZoom,
    mapRef,
    tilesBeingLoaded,
}: VisualizationsLayerProps) {
    const { state } = useMapContext()
    const datasets = [...state.current.datasets, ...state.catalog]

    const readyDatasets = datasets.filter((dataset) => {
        return dataset.status === 'ready'
    })

    const hasVizParams = (dataset: Dataset) =>
        dataset.vizParams !== undefined ||
        (dataset.className === 'EarthEngineDataset' &&
            dataset?.earthEngineVisualizations.length > 0) ||
        dataset.className === 'TileServerDataset'
    const visibleDatasets = readyDatasets.filter(
        (dataset) => dataset.isVisible && hasVizParams(dataset)
    )

    const hoveredDatasetId = state.hoveredCardDatasetVersionId
    const readyMapDatasets = state.current.datasets
        .filter((dataset) => {
            return dataset.status === 'ready'
        })
        .filter((dataset) => dataset.id != hoveredDatasetId)
    const hoveredDataset = datasets.find(
        (dataset) => dataset.id == hoveredDatasetId
    )

    React.useEffect(() => {
        const interval = setInterval(() => {
            if (mapRef.current) {
                // Datasets are sorted by layer order, so we must reverse
                // them first since moveLayer is FIFO
                const reversedVisibleDatasets = [...visibleDatasets].reverse()

                // move all the vector datasets to the front
                const vectorDatasets = reversedVisibleDatasets.filter(
                    (dataset) => dataset.type === 'vector'
                )
                vectorDatasets.forEach((dataset) => {
                    mapRef.current.moveLayer(layerID(dataset))
                })

                // reorder the datasets based on the layer order
                const rasterDatasets = reversedVisibleDatasets.filter(
                    (dataset) => dataset.type === 'raster'
                )
                rasterDatasets.forEach((dataset) => {
                    mapRef.current.moveLayer(layerID(dataset))
                })

                // move loading layer to the front if it exists
                if (
                    mapRef.current.getLayer(
                        TILES_BEING_LOADED_LAYER_ID + '-fill'
                    )
                ) {
                    mapRef.current.moveLayer(
                        TILES_BEING_LOADED_LAYER_ID + '-fill'
                    )
                    mapRef.current.moveLayer(
                        TILES_BEING_LOADED_LAYER_ID + '-outline'
                    )
                }
            }
        }, 100)

        return () => clearInterval(interval)
    }, [visibleDatasets, mapRef])

    return (
        <>
            <CommentThreadLayer />
            {visibleDatasets.map((dataset: Dataset) => (
                <React.Fragment key={dataset.id + '-fragment'}>
                    {dataset.type === 'vector' ? (
                        <VectorDatasetLayer
                            key={dataset.id + '-vector'}
                            dataset={dataset}
                        />
                    ) : (
                        <RasterDatasetLayer
                            key={dataset.id + '-raster'}
                            dataset={dataset}
                        />
                    )}
                </React.Fragment>
            ))}
            {readyMapDatasets.map((dataset: Dataset) => (
                <BoundsLayer
                    key={dataset.id + '-bounds'}
                    dataset={dataset}
                    hovered={false}
                />
            ))}
            {hoveredDataset && (
                <BoundsLayer
                    key={hoveredDataset.id + '-bounds'}
                    dataset={hoveredDataset}
                    hovered={true}
                />
            )}
            <TileLoadingLayer tilesBeingLoaded={tilesBeingLoaded} />
        </>
    )
}

export default VisualizationsLayer
