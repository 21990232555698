import { SupabaseClient } from '@supabase/supabase-js'
import { Viz } from '../types/viz.ts'

export async function getLatestVizParamsForDatasetVersion(
    supabaseClient: SupabaseClient,
    datasetVersionId: string
): Promise<Viz> {
    const { data, error } = await supabaseClient
        .from('viz_params')
        .select('*')
        .eq('dataset_version_id', datasetVersionId)
        .order('created_at', { ascending: false })
        .limit(1)

    if (error) {
        throw error
    }

    return data[0]
}
