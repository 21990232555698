import React from 'react'
import {
    Button,
    ButtonGroup,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    IconButton,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material'
import Box from '@mui/material/Box'
import {
    Add,
    Grade,
    Star,
    StarOutline,
    ThumbDown,
    ThumbDownAlt,
    ThumbDownOffAlt,
    ThumbUp,
    ThumbUpAlt,
    ThumbUpOffAlt,
} from '@mui/icons-material'
import { Dataset } from '../../types/dataset.ts'

export interface DatasetSearchResult {
    dataset: Dataset
    aiSummary: string
}

interface DatasetSearchListItemProps {
    result: DatasetSearchResult
    onAddToMap: (dataset: Dataset) => void
    onUpvote: () => void
    onDownvote: () => void
    isUpvoted: boolean
    isDownvoted: boolean
}

const DatasetSearchListItem: React.FC<DatasetSearchListItemProps> = ({
    result,
    onAddToMap,
    onUpvote,
    onDownvote,
    isUpvoted,
    isDownvoted,
}) => {
    const [recommendationState, setRecommendationState] = React.useState<
        'upvoted' | 'downvoted' | null
    >(isUpvoted ? 'upvoted' : isDownvoted ? 'downvoted' : null)

    const handleThumbsUpClick = () => {
        onUpvote()
        if (recommendationState === 'upvoted') {
            setRecommendationState(null)
        } else {
            setRecommendationState('upvoted')
        }
    }

    const handleThumbsDownClick = () => {
        onDownvote()
        if (recommendationState === 'downvoted') {
            setRecommendationState(null)
        } else {
            setRecommendationState('downvoted')
        }
    }

    return (
        <Card
            className="dataset-list-item"
            elevation={5}
            sx={{ display: 'flex', marginBottom: '10px' }}
        >
            <Stack direction="column" spacing={1} width={'100%'}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                    }}
                >
                    <CardMedia
                        component="img"
                        image={result.dataset.thumbnailUrl}
                        alt="Dataset example"
                        sx={{ width: 50, height: 50, borderRadius: '8px' }}
                    />
                    <CardContent sx={{ paddingTop: '0' }}>
                        <Typography
                            variant="h6"
                            sx={{ wordBreak: 'break-all' }}
                        >
                            {result.dataset.name}
                        </Typography>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            component="pre"
                            sx={{
                                whiteSpace: 'pre-wrap',
                                maxHeight: '200px',
                                overflowY: 'auto',
                            }}
                        >
                            {result.aiSummary}
                        </Typography>
                        {/*FIXME*/}
                        {/*<Stack direction="row" spacing={2}>*/}
                        {/*    <Typography*/}
                        {/*        variant="body2"*/}
                        {/*        color="textSecondary"*/}
                        {/*        component="p"*/}
                        {/*    >*/}
                        {/*        <strong>From:</strong>{' '}*/}
                        {/*        {result.startDatetime.toLocaleString()}*/}
                        {/*    </Typography>*/}
                        {/*    <Typography*/}
                        {/*        variant="body2"*/}
                        {/*        color="textSecondary"*/}
                        {/*        component="p"*/}
                        {/*    >*/}
                        {/*        <strong>To:</strong>{' '}*/}
                        {/*        {result.endDatetime.toLocaleString()}*/}
                        {/*    </Typography>*/}
                        {/*    <Typography*/}
                        {/*        variant="body2"*/}
                        {/*        color="textSecondary"*/}
                        {/*        component="p"*/}
                        {/*    >*/}
                        {/*        <strong>Spatial Resolution:</strong>{' '}*/}
                        {/*        {result.spatialResolution} meters*/}
                        {/*    </Typography>*/}
                        {/*    <Typography*/}
                        {/*        variant="body2"*/}
                        {/*        color="textSecondary"*/}
                        {/*        component="p"*/}
                        {/*    >*/}
                        {/*        <strong>Temporal Resolution:</strong>{' '}*/}
                        {/*        {result.temporalResolution}*/}
                        {/*    </Typography>*/}
                        {/*</Stack>*/}
                    </CardContent>
                    <CardActions
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            paddingTop: 0,
                            paddingRight: 0,
                        }}
                    >
                        <Stack direction={'column'} spacing={1}>
                            <Tooltip title={'Show the dataset on the map'}>
                                <Button
                                    variant="contained"
                                    size="small"
                                    color="primary"
                                    endIcon={<Add />}
                                    onClick={() => onAddToMap(result.dataset)}
                                >
                                    Add
                                </Button>
                            </Tooltip>
                            <ButtonGroup>
                                <Tooltip
                                    title={
                                        "This is what I'm looking for. Show me more like this in the next search!"
                                    }
                                >
                                    <IconButton onClick={handleThumbsUpClick}>
                                        {recommendationState == 'upvoted' ? (
                                            <ThumbUpAlt />
                                        ) : (
                                            <ThumbUpOffAlt />
                                        )}
                                    </IconButton>
                                </Tooltip>
                                <Tooltip
                                    title={
                                        "This is not what I'm looking for. Show me less like this in the next search."
                                    }
                                >
                                    <IconButton onClick={handleThumbsDownClick}>
                                        {recommendationState == 'downvoted' ? (
                                            <ThumbDownAlt />
                                        ) : (
                                            <ThumbDownOffAlt />
                                        )}
                                    </IconButton>
                                </Tooltip>
                            </ButtonGroup>
                        </Stack>
                    </CardActions>
                </Box>
            </Stack>
        </Card>
    )
}

export default DatasetSearchListItem
