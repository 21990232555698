import { ThemeOptions } from '@mui/material/styles'

export const themeOptions: ThemeOptions = {
    // The full docs are here: https://mui.com/material-ui/customization/theme-components/#theme-default-props
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    zIndex: 1000,
                    padding: '12px 8px',
                },
            },
        },
    },
    typography: {
        fontSize: 11,
        color: null,
        h1: {
            color: '#E6F6E7',
            fontFamily: 'PPNeueMachina-PlainRegular',
        },
        h2: {
            color: '#E6F6E7',
            fontFamily: 'PPNeueMachina-PlainRegular',
        },
        h3: {
            color: '#E6F6E7',
            fontFamily: 'PPNeueMachina-PlainRegular',
        },
        h4: {
            color: '#E6F6E7',
            fontFamily: 'PPNeueMachina-PlainRegular',
        },
        h5: {
            color: '#E6F6E7',
            fontFamily: 'PPNeueMachina-PlainRegular',
            fontWeight: 400,
            fontSize: '16px',
        },
        h6: {
            color: '#E6F6E7',
            fontFamily: 'PPNeueMachina-PlainRegular',
            fontWeight: 400,
            fontSize: '14px',
        },
        subtitle1: {
            color: '#E6F6E7',
            fontFamily: 'PPNeueMachina-PlainRegular',
        },
        body1: {
            color: '#E6F6E7',
            fontFamily: 'PPNeueMachina-PlainRegular',
        },
        body2: {
            // No idea why there's another color here somewhere
            color: 'rgba(230, 246, 231, 0.5) !important',
            fontFamily: 'PPNeueMachina-PlainRegular',
            fontWeight: 400,
            fontSize: '12px',
        },
        button: {
            textTransform: 'uppercase',
            fontFamily: 'IBM Plex Mono',
            fontWeight: 400,
            fontSize: '0.9rem',
        },
    },
    shape: {
        borderRadius: 8,
    },
    palette: {
        mode: 'dark',
        primary: {
            main: '#E6F6E7',
            light: '#E6F6E750',
            contrastText: '#1f1e21',
        },
        secondary: {
            main: '#90CAF9',
        },
        success: {
            main: '#2BFF73',
        },
        text: {
            primary: '#FFFFFF',
            secondary: 'rgba(230, 246, 231, 0.5)',
        },
        background: {
            paper: '#121212',
        },
        action: {
            active: '#FFFFFF',
            hover: '#FFFFFF10',
            disabledOpacity: 0.5,
        },
        divider: 'rgba(255, 255, 255, 0.1)',
    },
}
