import { Dataset } from 'types/dataset'

export function boundsStringToArray(
    boundsString: string
): [number, number, number, number] {
    // Bounds is a postgres string in the form of
    // 'BOX(xmin ymin, xmax ymax)'
    const parsedBounds = boundsString
        .replace('BOX(', '')
        .replace(')', '')
        .replace(',', ' ')
        .split(' ')
        .map(parseFloat)
    if (parsedBounds.length !== 4) {
        throw new Error('Invalid bounds string: ' + boundsString)
    }
    return parsedBounds as [number, number, number, number]
}

export function formatDate(date: Date): string {
    const hasTime =
        date.getUTCHours() !== 0 ||
        date.getUTCMinutes() !== 0 ||
        date.getUTCSeconds() !== 0
    if (hasTime) {
        return `${date.toISOString().split('T')[0]} ${date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })}`
    }
    return date.toISOString().split('T')[0]
}

export function formatDateTimeForDisplay(date: Date) {
    let locale = 'en-US'
    if (typeof navigator !== 'undefined' && navigator.language) {
        locale = navigator.language
    }

    const formatter = new Intl.DateTimeFormat(locale, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        timeZoneName: 'short',
    })

    return formatter.format(date)
}

export function filterDatasetsInView(
    datasets: Dataset[],
    bounds: [number, number, number, number]
) {
    return datasets.filter((dataset) => {
        const extent = dataset?.extent
        if (!extent) return true
        const boundsString = boundsStringToArray(extent)
        // If dataset has no bounds, include it
        if (!boundsString) return true

        const [west, south, east, north] = boundsString
        const [viewWest, viewSouth, viewEast, viewNorth] = bounds

        // Check if bounds intersect
        // One box is to the left of the other
        if (east < viewWest || viewEast < west) return false

        // One box is above the other
        if (south > viewNorth || viewSouth > north) return false

        // Bounds must intersect
        return true
    })
}
