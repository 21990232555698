import { createContext, useContext } from 'react'
import { Session, SupabaseClient } from '@supabase/supabase-js'
import { EarthscaleUser } from '../../api/user.ts'
import { UserProfile } from '../../types/user'

type SupabaseContextType = {
    client: SupabaseClient
    session: Session
    user: EarthscaleUser
    userProfile: UserProfile
    logout: () => void
    loginMapRef: React.MutableRefObject<any>
}

export const SupabaseContext = createContext<SupabaseContextType>(null)

export const useSupabaseContext = () => useContext(SupabaseContext)
