import React, { useState } from 'react'
import {
    Box,
    FormControl,
    IconButton,
    Input,
    TextField,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material'
import { ArrowDropDown, LocationOn } from '@mui/icons-material'
import MapSelector from './MapSelector.tsx'
import { useMapContext } from '../../context/map/mapContext.ts'
import SidebarStyle from './Sidebar.module.css'
import mapPin from '../../assets/icons/map-pin.svg'

const MapBox = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const { state, dispatch, updateMapMetadata } = useMapContext()
    const theme = useTheme()

    const currentMap = state.current.metadata

    const handleMapSelectorButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMapNameChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        updateMapMetadata(event.target.value, currentMap.description)
    }

    const handleMapDescriptionChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        updateMapMetadata(currentMap.name, event.target.value)
    }

    return (
        <Box className={SidebarStyle.mapBox}>
            <img
                src={mapPin as string}
                alt="Map Pin"
                className={SidebarStyle.mapPin}
            />
            <Box>
                <TextField
                    value={currentMap.name}
                    onChange={handleMapNameChange}
                    multiline={true}
                    InputProps={{
                        style: {
                            ...theme.typography.h5,
                            padding: '5px',
                            borderRadius: '8px',
                            border: 'none',
                        },
                    }}
                    sx={{
                        width: '100%',
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: 'transparent',
                            },
                        },
                    }}
                />
                <TextField
                    value={currentMap.description}
                    onChange={handleMapDescriptionChange}
                    multiline={true}
                    InputProps={{
                        style: {
                            ...theme.typography.body2,
                            color: theme.palette.text.secondary,
                            padding: '5px',
                            borderRadius: '8px',
                        },
                    }}
                    sx={{
                        width: '100%',
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: 'transparent',
                            },
                        },
                    }}
                />
            </Box>
            <Tooltip title="Select map" arrow>
                <IconButton
                    variant={'outlined'}
                    onClick={handleMapSelectorButtonClick}
                >
                    <ArrowDropDown color="primary" fontSize="large" />
                </IconButton>
            </Tooltip>
            <MapSelector anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
        </Box>
    )
}

export default MapBox
