import React from 'react'
import { Box, CircularProgress } from '@mui/material'
import * as loadingSpinner from '../../assets/loadingSpinnner/black-on-transparent.json'
import Lottie from 'react-lottie-player'

const LoadingScreen: React.FC = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                backgroundColor: 'primary.main',
                flexDirection: 'column',
            }}
        >
            <Lottie
                loop
                animationData={loadingSpinner}
                play
                style={{
                    width: 150,
                    height: 150,
                }}
            />
        </Box>
    )
}

export default React.memo(LoadingScreen)
