import React from 'react'
import { Box, Paper, Typography } from '@mui/material'

const MessageContainer: React.FC<{ myself: boolean }> = ({
    myself,
    children,
}) => {
    return (
        <Box
            display="flex"
            justifyContent={myself ? 'flex-end' : 'flex-start'}
            mb={2}
        >
            <Paper sx={{ maxWidth: '80%' }} elevation={myself ? 3 : 4}>
                {children}
            </Paper>
        </Box>
    )
}

interface ChatMessageProps {
    message: ChatMessage
}

export const ChatMessageBox: React.FC<ChatMessageProps> = ({ message }) => {
    const isMyself = message.sender === 'myself'

    return (
        <MessageContainer myself={isMyself}>
            <Typography variant="body1">{message.text}</Typography>
        </MessageContainer>
    )
}
