import * as React from 'react'
import {
    Button,
    CircularProgress,
    Stack,
    TextField,
    Typography,
} from '@mui/material'
import Box from '@mui/material/Box'
import { addDataset } from '../../api/dataset.ts'
import { useSupabaseContext } from '../../context/supabase/supabaseContext.ts'

function getNameFromURL(url: string): string {
    // The name is created as the last part of the url without the extension
    // It's capitalized and pascal case is converted to spaces
    // Underscores and dashes are replaced with spaces
    const strippedUrl = url.trim().replace(/\/$/, '')
    const parts = strippedUrl.split('/')
    const lastPart = parts[parts.length - 1]
    const name = lastPart.split('.')[0]
    const pascalCaseToSpaces = name.replace(/([A-Z])/g, ' $1')
    const noUnderscores = pascalCaseToSpaces.replace(/[-_]/g, ' ').trim()
    return noUnderscores.charAt(0).toUpperCase() + noUnderscores.slice(1)
}

type MetadataStepProps = {
    url: string
    onBackClick: () => void
    onClose: () => void
}

function MetadataStep({ url, onBackClick, onClose }: MetadataStepProps) {
    const supabaseContext = useSupabaseContext()
    const [name, setName] = React.useState<string>(getNameFromURL(url))
    const [addingDataset, setAddingDataset] = React.useState<boolean>(false)
    const [firstTry, setFirstTry] = React.useState<boolean>(true)
    const [error, setError] = React.useState<string | null>(null)

    const handleAdd = async () => {
        const accessToken = supabaseContext.session?.access_token
        if (!accessToken) {
            throw new Error('No access token found')
        }
        setAddingDataset(true)
        addDataset(url, name, accessToken)
            .then((response) => {
                setAddingDataset(false)
                onClose()
            })
            .catch((error) => {
                setAddingDataset(false)
                setFirstTry(false)
                setError(error.message)
            })
    }

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleAdd()
        }
    }

    return (
        <>
            <Stack direction={'column'} spacing={2} sx={{ p: '10px' }}>
                <TextField
                    autoFocus
                    margin="dense"
                    id="dataset-name"
                    label="Dataset Name"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    onKeyDown={handleKeyDown}
                    disabled={addingDataset}
                />
                {error && <Typography color={'error'}>{error}</Typography>}
            </Stack>
            <Stack direction={'row'} spacing={2} sx={{ pt: 2 }}>
                <Button
                    color="inherit"
                    disabled={addingDataset}
                    onClick={onBackClick}
                >
                    Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    alignContent={'center'}
                >
                    {addingDataset && <CircularProgress size={20} />}
                    <Button onClick={handleAdd} disabled={addingDataset}>
                        {firstTry ? 'Add' : 'Try again'}
                    </Button>
                </Stack>
            </Stack>
        </>
    )
}

export default MetadataStep
