import * as React from 'react'
import {
    Button,
    CircularProgress,
    IconButton,
    Stack,
    Typography,
    Snackbar,
    Alert,
} from '@mui/material'
import Box from '@mui/material/Box'
import { CopyAll } from '@mui/icons-material'
import { earthscaleCanRead } from '../../api/ingest'
import { useSupabaseContext } from '../../context/supabase/supabaseContext'
import { getSharedBucketPath } from '../../api/bucket'

function isGoogleStorageUrl(url: string): boolean {
    return url.startsWith('gs://')
}

function getBucketName(url: string): string | null {
    const match = url.match(/^gs:\/\/([^/]+)\/?/)
    if (match) {
        return match[1]
    }
    return null
}

function GoogleCloudErrorMessage({
    url,
    bucketName,
}: {
    url: string
    bucketName: string
}) {
    const { client } = useSupabaseContext()
    const [sharedBucketPath, setSharedBucketPath] = React.useState<
        string | null
    >(null)
    const [sharedBucketExists, setSharedBucketExists] = React.useState(true)
    const [open, setOpen] = React.useState(false)

    React.useEffect(() => {
        getSharedBucketPath(client)
            .then(setSharedBucketPath)
            .catch(() => {
                setSharedBucketExists(false)
            })
    }, [client])

    const copyCommand = `gcloud storage cp -r ${url} ${sharedBucketPath}`
    const grantAccessCommand = `gcloud storage buckets add-iam-policy-binding \\
    gs://${bucketName} \\
    --member=group:backend-services@earthscale.ai \\
    --role=roles/storage.objectViewer \\
&& gcloud storage buckets add-iam-policy-binding \\
    gs://${bucketName} \\
    --member=group:backend-services@earthscale.ai \\
    --role=roles/storage.legacyBucketReader`

    const handleClose = (
        event?: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === 'clickaway') {
            return
        }
        setOpen(false)
    }

    return (
        <Stack direction={'column'} spacing={2}>
            <Typography variant={'body1'}>
                It seems like your dataset is hosted on the "{bucketName}"
                bucket on Google Cloud Storage and is not accessible by
                Earthscale. You can either copy the dataset to a bucket that is
                accessible by Earthscale or give Earthscale access to the
                dataset.
            </Typography>

            {sharedBucketExists && (
                <>
                    <Typography variant={'body1'}>
                        Your organization already has a bucket that is
                        accessible by Earthscale. You can copy the dataset to
                        that bucket by running the following command:
                    </Typography>
                    <Box
                        sx={{
                            backgroundColor: 'black',
                            p: '10px',
                            borderRadius: '8px',
                        }}
                    >
                        <Stack
                            direction="row"
                            alignItems={'center'}
                            spacing={2}
                        >
                            <Typography variant={'body1'}>
                                <code>{copyCommand}</code>
                            </Typography>
                            <Box sx={{ flexGrow: '1' }} />
                            <Box>
                                <IconButton
                                    onClick={() => {
                                        navigator.clipboard.writeText(
                                            copyCommand
                                        )
                                        setOpen(true)
                                    }}
                                >
                                    <CopyAll />
                                </IconButton>
                            </Box>
                        </Stack>
                    </Box>
                </>
            )}
            <Typography variant={'body1'}>
                You can give Earthscale read-only access to the bucket by
                running the following command:
            </Typography>
            <Box
                sx={{
                    backgroundColor: 'black',
                    p: '10px',
                    borderRadius: '8px',
                }}
            >
                <Stack direction="row" alignItems={'center'} spacing={2}>
                    <Typography variant={'body1'}>
                        <pre>{grantAccessCommand}</pre>
                    </Typography>
                    {/*Box to fill the empty space*/}
                    <Box sx={{ flexGrow: '1' }} />
                    <Box>
                        <IconButton
                            onClick={() => {
                                navigator.clipboard.writeText(
                                    grantAccessCommand
                                )
                                setOpen(true)
                            }}
                        >
                            <CopyAll />
                        </IconButton>
                    </Box>
                </Stack>
            </Box>
            <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleClose}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    Command copied to clipboard
                </Alert>
            </Snackbar>
        </Stack>
    )
}

function RegularNoAccessMessage() {
    return (
        <Typography variant={'body1'}>
            It seems like your dataset is not publicly accessible. Please make
            sure that "backend-services@earthscale.ai" has read and list access.
        </Typography>
    )
}

type AccessStepProps = {
    url: string
    onNextClick: () => void
    onBackClick: () => void
}

function AccessStep({ url, onNextClick, onBackClick }: AccessStepProps) {
    const supabaseContext = useSupabaseContext()
    const [checkingAccess, setCheckingAccess] = React.useState<boolean>(true)
    const [hasAccess, setHasAccess] = React.useState<boolean | null>(null)

    React.useEffect(() => {
        // Putting this into a separate function as `useEffect` can't handle the
        // return promise
        const checkAccess = async () => {
            if (checkingAccess) {
                const accessToken = supabaseContext.session?.access_token
                if (!accessToken) {
                    throw new Error('No access token found')
                }
                const canAccess = await earthscaleCanRead(url, accessToken)
                if (canAccess) {
                    onNextClick()
                } else {
                    setHasAccess(canAccess)
                    setCheckingAccess(false)
                }
            }
        }
        checkAccess()
    }, [checkingAccess])

    const errorComponent =
        url && isGoogleStorageUrl(url) && getBucketName(url) ? (
            <GoogleCloudErrorMessage
                url={url}
                bucketName={getBucketName(url)}
            />
        ) : (
            <RegularNoAccessMessage />
        )

    return (
        <Stack direction="column" spacing={2}>
            <Box sx={{ p: '10px' }}>
                {checkingAccess && (
                    <Stack direction="row" alignItems={'center'} spacing={2}>
                        <Typography variant={'body1'}>
                            Checking access to <code>{url}</code>...
                        </Typography>
                        <Box>
                            <CircularProgress size={20} />
                        </Box>
                    </Stack>
                )}
                {!checkingAccess && !hasAccess && errorComponent}
            </Box>
            <Stack direction={'row'} spacing={2} sx={{ pt: 2 }}>
                <Button
                    color="inherit"
                    disabled={checkingAccess}
                    onClick={onBackClick}
                >
                    Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button
                    onClick={() => setCheckingAccess(true)}
                    disabled={checkingAccess}
                >
                    Try Again
                </Button>
            </Stack>
        </Stack>
    )
}

export default AccessStep
