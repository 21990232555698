import React from 'react'
import {
    Box,
    Button,
    Divider,
    IconButton,
    Paper,
    TextField,
    Typography,
} from '@mui/material'
import { useCommentContext } from '../../context/comment/commentContext'
import { addThread } from '../../api/comments'
import { Close } from '@mui/icons-material'
import { useSupabaseContext } from '../../context/supabase/supabaseContext'
import { useMapContext } from '../../context/map/mapContext'
import { MAX_COMMENT_LENGTH, MAX_COMMENT_TITLE_LENGTH } from '../../constants'

const NewThreadBox = () => {
    const { dispatch, state: commentState } = useCommentContext()
    const { state: mapState } = useMapContext()
    const { client, userProfile } = useSupabaseContext()
    const emptyCommentText = 'Add a comment...'

    const onClose = () => {
        dispatch({ type: 'SET_COMMENT_MODE', isCommentMode: false })
    }

    const setThreadTitle = (title: string) => {
        title = title.replace(/(\r\n|\n|\r)/gm, ' ')
        dispatch({ type: 'SET_DRAFT_TITLE', draft_title: title })
    }

    const setComment = (comment: string) => {
        dispatch({ type: 'SET_DRAFT_COMMENT', draft_comment: comment })
    }

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        if (mapState.current === null) return
        const draft = commentState.draftThread
        if (draft === null) return
        if (!draft.comment.trim()) return

        try {
            const newThread = await addThread(
                mapState.current?.metadata?.id,
                draft.location,
                draft.comment,
                draft.title,
                client,
                userProfile
            )
            dispatch({ type: 'ADD_THREAD', thread: newThread })
        } catch (error) {
            console.error('Failed to add thread:', error)
        }
    }

    return (
        <Paper
            sx={{
                position: 'absolute',
                right: 16,
                width: {
                    xs: '300px',
                    '@media (min-width:1000px)': {
                        width: '400px',
                    },
                },
                maxHeight: '80vh',
                overflow: 'auto',
                zIndex: 1000,
            }}
        >
            <form onSubmit={handleSubmit}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        p: 1,
                    }}
                >
                    <Box sx={{ flex: 1 }}>
                        <TextField
                            fullWidth
                            placeholder="Comment Title (optional)"
                            variant="outlined"
                            size="small"
                            tabIndex={3}
                            value={commentState.draftThread.title}
                            onChange={(e) => setThreadTitle(e.target.value)}
                            inputProps={{
                                maxLength: MAX_COMMENT_TITLE_LENGTH,
                            }}
                        />
                        <Typography
                            color="text.secondary"
                            variant="caption"
                            sx={{ px: 1 }}
                        >
                            {'X, Y: '}
                            {commentState.draftThread.location.lng.toFixed(6)}
                            {', '}
                            {commentState.draftThread.location.lat.toFixed(6)}
                        </Typography>
                    </Box>
                    <IconButton onClick={onClose}>
                        <Close />
                    </IconButton>
                </Box>
                <Divider />
                <Box sx={{ pt: '16px', pb: '8px', px: '8px' }}>
                    <TextField
                        fullWidth
                        multiline
                        tabIndex={1}
                        rows={3}
                        placeholder={emptyCommentText}
                        value={commentState.draftThread.comment}
                        onChange={(e) => setComment(e.target.value)}
                        onKeyDown={(e) => {
                            if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
                                e.preventDefault()
                                handleSubmit(e)
                            }
                        }}
                        variant="outlined"
                        size="small"
                        helperText="Press ⌘+Enter to submit"
                        inputProps={{
                            maxLength: MAX_COMMENT_LENGTH,
                        }}
                        autoFocus
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                        }}
                    >
                        <Button
                            tabIndex={2}
                            type="submit"
                            variant="contained"
                            disabled={!commentState.draftThread.comment.trim()}
                            size="small"
                            sx={{ minWidth: '80px' }}
                        >
                            Submit
                        </Button>
                    </Box>
                </Box>
            </form>
        </Paper>
    )
}

export default NewThreadBox
