import { SupabaseClient } from '@supabase/supabase-js'
import { getOrgId } from './user'

export async function getSharedBucketPath(
    supabaseClient: SupabaseClient
): Promise<string> {
    const orgId = await getOrgId(supabaseClient)
    if (!orgId) {
        throw new Error('Organization ID not found')
    }

    const { data, error } = await supabaseClient
        .from('shared_buckets')
        .select('bucket_path')
        .eq('org_id', orgId)
        .single()

    if (error) {
        throw error
    }

    if (!data) {
        throw new Error('No shared bucket found for organization')
    }

    // Ensure bucket path has trailing slash
    const bucketPath = data.bucket_path
    return bucketPath.endsWith('/') ? bucketPath : `${bucketPath}/`
}
