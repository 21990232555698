import React, { useEffect, useRef } from 'react'
import { Box } from '@mui/material'
import { ChatMessageBox } from './ChatMessageBox.tsx'

export interface Location {
    name: string
    bounds: [number, number, number, number]
}

interface ChatProps {
    messages: ChatMessage[]
}

const Chat: React.FC<ChatProps> = ({ messages }) => {
    const chatContainerRef = useRef<HTMLDivElement>(null)
    const lastMessageRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (lastMessageRef.current) {
            lastMessageRef.current.scrollIntoView({ behavior: 'smooth' })
        }
    }, [messages])

    return (
        <Box
            display="flex"
            flexDirection="column"
            height="100%"
            width="100%"
            overflow="auto"
            ref={chatContainerRef}
        >
            <Box padding="0px 8px">
                {messages.map((msg, index) => (
                    <Box
                        key={index}
                        ref={
                            index === messages.length - 1
                                ? lastMessageRef
                                : null
                        }
                    >
                        <ChatMessageBox message={msg} />
                    </Box>
                ))}
            </Box>
        </Box>
    )
}

export default Chat
