import { RealtimeChannel, SupabaseClient } from '@supabase/supabase-js'
import {
    Dataset,
    DatasetDomain,
    DatasetProcessingStatus,
    DatasetType,
    VizType,
    DimensionInfo,
} from '../types/dataset'
import { v4 as uuid4 } from 'uuid'
import {
    MultibandRasterVizParams,
    PseudocolorRasterVizParams,
    Viz,
    VizParams,
    EarthEngineVisualization,
    EarthEngineVizParams,
} from '../types/viz'

type _DatasetMetadata = {
    value_map?: { [key: string]: number | number[] }
    bands?: string[]
    min_zoom: number
    max_zoom?: number | null
    min_maxes_per_band?: {
        [key: string]: [number | undefined, number | undefined]
    }
    attributes?: { [key: string]: string }
}

type DBDataset = {
    id: string
    dataset_id: string
    name: string
    updated_at: string
    type: DatasetType
    is_deleted: boolean
    extent: string
    dimension_info: DimensionInfo | null
    class_name: string
    metadata: _DatasetMetadata
    status: DatasetProcessingStatus
    domain: DatasetDomain
    org_id: string
    user_id: string
    error: string | null
    traceback: string | null
    source: string
    is_public: boolean
    viz_params?: DBVizParams[]
    thumbnail_url?: string | null
    data_region?: string | null
    earth_engine_visualizations?: DBEarthEngineVisualization[]
}

type DBEarthEngineVisualization = {
    id: string
    name: string
    earth_engine_viz_params: EarthEngineVizParams
    dataset_version_id: string
    created_at: Date
    updated_at: Date
    deleted_at: Date
}

type DBVizParams = {
    id: string
    type: VizType
    params: VizParams
    dataset_version_id: string
    created_at: string
    dataset_name: string
}

type AddResponse = {
    datasetId: string
    datasetVersionId: string
}

const PROCESSING_STATES = ['processing', 'not_started']

function ensureVizParamsAreValid(dataset: Dataset): Dataset {
    const paramTypesToCheck = [
        'continuous_singleband_raster',
        'continuous_multiband_raster',
    ]
    if (!paramTypesToCheck.includes(dataset.vizType)) {
        return dataset
    }

    let params: PseudocolorRasterVizParams | MultibandRasterVizParams
    if (dataset.vizType === 'continuous_singleband_raster') {
        params = dataset.vizParams as PseudocolorRasterVizParams
    } else if (dataset.vizType === 'continuous_multiband_raster') {
        params = dataset.vizParams as MultibandRasterVizParams
    }

    // TODO: this is a temporary hack to ensure that the min/max values are always present in the viz params.
    // we've since updated the backend to always return min/max values, but this is a stopgap measure to ensure
    // that old viz params are still compatible with the new backend without a migration
    // https://github.com/earthscale/earthscale/pull/239
    const currentMinMaxesPerBand = {
        ...Object.fromEntries(
            Object.entries(dataset.minMaxesPerBand).map(
                ([band, [min, max]]) => [band, [min ?? 0, max ?? 1]]
            )
        ),
        ...Object.fromEntries(
            Object.entries(params.minMaxesPerBand).map(([band, [min, max]]) => [
                band,
                [min ?? 0, max ?? 1],
            ])
        ),
    }
    dataset.vizParams.minMaxesPerBand = currentMinMaxesPerBand

    return dataset
}

async function insertVizParams(
    supabaseClient: SupabaseClient,
    datasetVersionId: string,
    datasetName: string,
    vizId: string,
    vizType: VizType,
    vizParams: VizParams
): Promise<void> {
    const row = {
        id: vizId,
        type: vizType,
        params: vizParams,
        dataset_version_id: datasetVersionId,
        dataset_name: datasetName,
        created_at: new Date().toISOString(),
    }

    const { data, error } = await supabaseClient
        .from('viz_params')
        .insert([row])

    if (error) {
        console.error(error)
    }
}

function datasetCopyHydratedProperties(from: Dataset, to: Dataset): void {
    to.isVisible = from.isVisible
    to.selectedDimensions = from.selectedDimensions
    to.isBeingEdited = from.isBeingEdited
    to.cardIsHovered = from.cardIsHovered
    to.isNewVersionAvailable = from.isNewVersionAvailable
}

function convertDBEarthEngineVisualizationToEarthEngineVisualization(
    dbEarthEngineVisualization: DBEarthEngineVisualization
): EarthEngineVisualization {
    return {
        id: dbEarthEngineVisualization.id,
        name: dbEarthEngineVisualization.name,
        earthEngineVizParams:
            dbEarthEngineVisualization.earth_engine_viz_params,
    }
}

function convertDimensions(dbDataset: DBDataset): DimensionInfo {
    let updatedDimensionInfo: DimensionInfo = dbDataset.dimension_info
    const timeDimension = updatedDimensionInfo?.dimensions?.find(
        (dimension) => dimension.name === 'time'
    )
    const hasDates = timeDimension?.values !== undefined

    if (dbDataset.class_name === 'EarthEngineDataset' && hasDates) {
        const dims = updatedDimensionInfo?.dimensions
        updatedDimensionInfo.dimensions = dims.filter(
            (dim) => dim.name !== 'time'
        )
    }

    // If there are dates, we need to convert them to Date objects
    if (hasDates) {
        updatedDimensionInfo.dimensions = updatedDimensionInfo.dimensions?.map(
            (dim) => {
                if (dim.name === 'time') {
                    return {
                        ...dim,
                        values: dim.values.map((value: number) => {
                            return new Date(value)
                        }),
                    }
                }
                return dim
            }
        )
    }

    return updatedDimensionInfo
}

async function convertDBDatasetToDataset(
    dbDataset: DBDataset,
    viz: Viz | null
): Promise<Dataset> {
    let dimensionInfo = null
    let initSelectedDimensions: { [key: string]: number } = {}
    if (dbDataset.dimension_info) {
        dimensionInfo = convertDimensions(dbDataset)
        initSelectedDimensions = {
            ...Object.fromEntries(
                dimensionInfo.dimensions.map((dim) => [dim.name, 0])
            ),
        }
    }

    const dataset: Dataset = {
        id: dbDataset.id,
        datasetId: dbDataset.dataset_id,
        name: dbDataset.name,
        createdAt: dbDataset.created_at,
        updatedAt: dbDataset.updated_at,
        dimensionInfo: dimensionInfo,
        extent: dbDataset.extent,
        isDeleted: dbDataset.is_deleted,
        domain: dbDataset.domain,
        type: dbDataset.type,
        status: dbDataset.status,
        className: dbDataset.class_name,
        error: dbDataset.error,
        traceback: dbDataset.traceback,
        dataRegion: dbDataset?.data_region,
        source: dbDataset.source,
        isPublic: dbDataset.is_public,
        bands: dbDataset.metadata.bands,
        minMaxesPerBand: dbDataset.metadata.min_maxes_per_band,
        minZoom: dbDataset.metadata.min_zoom || 0,
        maxZoom: dbDataset.metadata.max_zoom || null,
        earthEngineVisualizations: dbDataset.earth_engine_visualizations?.map(
            convertDBEarthEngineVisualizationToEarthEngineVisualization
        ),
        selectedEarthEngineVisualizationIndex: -1,
        vizParams: viz?.vizParams,
        vizType: viz?.vizType,
        vizId: viz?.id,
        isVisible: false,
        selectedDimensions: initSelectedDimensions,
        isBeingEdited: false,
        cardIsHovered: false,
        isNewVersionAvailable: false,
        thumbnailUrl: dbDataset.metadata?.thumbnail_url,
        attributes: dbDataset.metadata?.attributes?.reduce(
            (acc, attr) => {
                acc[attr.name] = attr.value
                return acc
            },
            {} as { [key: string]: string }
        ),
    }
    if (dataset.earthEngineVisualizations?.length) {
        dataset.selectedEarthEngineVisualizationIndex = 0
    }
    if (!dataset.minMaxesPerBand) {
        dataset.minMaxesPerBand = {}
        dataset.bands?.forEach((band) => {
            dataset.minMaxesPerBand[band] = [undefined, undefined]
        })
    }

    const validatedDataset = ensureVizParamsAreValid(dataset)
    return validatedDataset
}

async function fetchDatasets(
    supabaseClient: SupabaseClient
): Promise<Dataset[]> {
    const latestDatasetsQuery = supabaseClient
        .from('datasets_latest')
        .select('*, viz_params(*), earth_engine_visualizations(*)')
        .order('updated_at', { ascending: false })
    const { data, error } = await latestDatasetsQuery
    if (error) {
        console.error(error)
        return
    }

    // Convert DBDataset to Dataset
    const datasets = data.map(async (dataset: DBDataset) => {
        const latestDBVizParams = dataset.viz_params.sort((a, b) => {
            return (
                new Date(b.created_at).getTime() -
                new Date(a.created_at).getTime()
            )
        })

        // Visualizations may not exist if it comes from a tileserver.
        let latestViz: Viz | null = null
        if (latestDBVizParams.length > 0) {
            latestViz = {
                id: latestDBVizParams[0].id,
                vizType: latestDBVizParams[0].type,
                vizParams: latestDBVizParams[0].params,
            }
        }

        return convertDBDatasetToDataset(dataset, latestViz, supabaseClient)
    })

    return Promise.all(datasets).then((results) =>
        results.filter((dataset) => dataset !== undefined)
    )
}

async function subscribeToDatasetChanges(
    supabaseClient: SupabaseClient,
    callback: (payload: any) => void
): Promise<RealtimeChannel> {
    const channel = supabaseClient
        .channel('dataset-changes')
        .on(
            'postgres_changes',
            {
                event: '*',
                schema: 'public',
                table: 'dataset_versions',
            },
            callback
        )
        .on(
            'postgres_changes',
            {
                event: '*',
                schema: 'public',
                table: 'viz_params',
            },
            callback
        )
        .subscribe()
    return channel
}

async function deleteDataset(
    supabaseClient: SupabaseClient,
    datasetVersionId: string
) {
    // Deletion works by creating a copy of the dataset with a new ID and setting
    // is_deleted to true
    const newDatasetVersionId = uuid4()
    // First, fetching the dataset
    const { data, error } = await supabaseClient
        .from('dataset_versions')
        .select('*')
        .eq('id', datasetVersionId)
    if (error) {
        console.error(error)
        return
    }
    const currentVersion = data[0]
    // Then, inserting the new dataset, make sure that updated_at is set to the current
    // time
    const { data: insertData, insertError } = await supabaseClient
        .from('dataset_versions')
        .insert({
            ...currentVersion,
            id: newDatasetVersionId,
            is_deleted: true,
            updated_at: new Date().toISOString(),
        })
    if (insertError) {
        console.error(insertError)
        return
    }
}

async function addDataset(
    url: string,
    name: string,
    accessToken: string
): Promise<AddResponse> {
    const backendUrl = import.meta.env.EARTHSCALE_BACKEND_URL as string
    const strippedBackendUrl = backendUrl.replace(/\/+$/, '')

    return fetch(`${strippedBackendUrl}/datasets/add`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
            url: url,
            name: name,
        }),
    }).then(async (response) => {
        const responseJson = await response.json()
        if (!response.ok) {
            const details = responseJson?.detail?.message
            if (details) {
                throw Error(details)
            }
            throw Error('Failed to add dataset.')
        } else {
            return {
                datasetId: responseJson.dataset_id,
                datasetVersionId: responseJson.dataset_version_id,
            }
        }
    })
}

export type { DBDataset, DBVizParams }
export {
    convertDBDatasetToDataset,
    datasetCopyHydratedProperties,
    subscribeToDatasetChanges,
    fetchDatasets,
    insertVizParams,
    deleteDataset,
    addDataset,
    PROCESSING_STATES,
}
