import { GlobalStyles, useTheme } from '@mui/material'
import React from 'react'

export const GlobalCSS = () => {
    const theme = useTheme()

    return (
        <GlobalStyles
            styles={{
                '*::-webkit-scrollbar': {
                    width: '0.4em',
                },
                '*::-webkit-scrollbar-thumb': {
                    backgroundColor: theme.palette.primary.light,
                    borderRadius: '10px',
                },
            }}
        />
    )
}
