import * as React from 'react'
import { useState } from 'react'
import { Dataset } from '../../types/dataset.ts'
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    IconButton,
    ListItemText,
    Menu,
    MenuItem,
    Stack,
    Tooltip,
} from '@mui/material'
import DatasetCardStyle from './DatasetCard.module.css'
import VisibilityButton from './VisibilityButton'
import ZoomButton from './ZoomButton'
import { Delete, Warning } from '@mui/icons-material'
import DateSlider from './DateSlider'
import { useMapContext } from '../../context/map/mapContext.ts'
import raster from '../../assets/icons/raster.svg'
import EditVizButton from './EditVizButton.tsx'
import { PROCESSING_STATES } from '../../api/dataset.ts'
import ColorLensOutlinedIcon from '@mui/icons-material/ColorLensOutlined'
import DimensionSelector from './DimensionSelector.js'
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline'
import ViewListIcon from '@mui/icons-material/ViewList'

type DatasetCardProps = {
    dataset: Dataset
    flyToDatasetBounds: (dataset: Dataset) => void
    showVizSettings: boolean
    showRemoveFromMap: boolean
    showVisibility: boolean
}

type DatasetDeleteButtonProps = {
    onClick: () => void
    className?: string
}

function DatasetDeleteButton({ onClick, className }: DatasetDeleteButtonProps) {
    return (
        <Tooltip title={'Delete Dataset from Catalog'}>
            <IconButton onClick={onClick} className={className}>
                <Delete />
            </IconButton>
        </Tooltip>
    )
}

function DatasetCard({
    dataset,
    flyToDatasetBounds,
    showVizSettings,
    showRemoveFromMap,
    showVisibility,
}: DatasetCardProps) {
    const {
        deleteDatasetFromCatalog,
        removeDatasetFromMap,
        selectEarthEngineVisualization,
    } = useMapContext()
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
    const [removeFromMapDialogOpen, setRemoveFromMapDialogOpen] =
        React.useState(false)
    const [datasetToDelete, setDatasetToDelete] = useState<Dataset | null>(null)

    const [visualizationSelectionMenuOpen, setVisualizationSelectionMenuOpen] =
        React.useState(false)

    const [useTimeSlider, setUseTimeSlider] = React.useState(true)

    const visibilityButton = VisibilityButton({
        dataset,
        className: DatasetCardStyle.icon,
    })
    const zoomButton = ZoomButton({
        dataset,
        flyToDatasetBounds,
        className: DatasetCardStyle.hoverIcon,
    })

    const backgroundColor = dataset.vizParams?.color || 'white'

    let secondaryText =
        dataset.type == 'raster' ? 'Raster Dataset' : 'Vector Dataset'
    if (dataset.source == 'local') {
        secondaryText =
            'Local ' +
            secondaryText +
            ' - Only visible to you and not persisted'
    }

    const handleDeleteDatasetClose = () => {
        setDeleteDialogOpen(false)
        setDatasetToDelete(null)
    }

    const handleRemoveFromMapDialogClose = () => {
        setRemoveFromMapDialogOpen(false)
    }

    const handleRemoveFromMap = () => {
        removeDatasetFromMap(dataset.id)
        setRemoveFromMapDialogOpen(false)
    }

    // Get bands used in current visualization
    const getVisualizationBands = (): string[] => {
        if (!dataset.vizParams) return []

        switch (dataset.vizType) {
            case 'continuous_singleband_raster':
                return [dataset.vizParams.band]
            case 'continuous_multiband_raster':
                return [
                    dataset.vizParams.red,
                    dataset.vizParams.green,
                    dataset.vizParams.blue,
                ]
            case 'categorical_raster':
                return [dataset.vizParams.band]
            default:
                return []
        }
    }

    // TODO: Filter dimensions to only include those present in the visualization bands
    const getRelevantDimensions = () => {
        if (!dataset.dimensionInfo?.dimensions) return []
        return dataset.dimensionInfo.dimensions
    }

    const relevantDimensions = getRelevantDimensions()
    const hasTimeDimension = relevantDimensions.find(
        (dim) => dim.name === 'time'
    )
    const nonTimeDimensions = relevantDimensions.filter(
        (dim) => dim.name !== 'time'
    )
    const hasAnyDimensions = relevantDimensions.length > 0

    return (
        <>
            {/*Remove dataset from map dialog*/}
            <Dialog
                open={removeFromMapDialogOpen}
                onClose={handleRemoveFromMapDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    "{dataset.name}" will be deleted from this map. It will
                    remain in the catalog.
                </DialogTitle>
                <DialogActions>
                    <Button onClick={handleRemoveFromMapDialogClose}>
                        Cancel
                    </Button>
                    <Button onClick={handleRemoveFromMap} autoFocus>
                        Remove from this map
                    </Button>
                </DialogActions>
            </Dialog>
            {/*Delete dataset dialog*/}
            {datasetToDelete && (
                <Dialog
                    open={deleteDialogOpen}
                    onClose={handleDeleteDatasetClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Delete Dataset "{datasetToDelete.name}" from Catalog?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to permanently delete the
                            dataset "{datasetToDelete.name}" from the catalog?
                            This action cannot be undone.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDeleteDatasetClose}>
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                deleteDatasetFromCatalog(datasetToDelete.id)
                                handleDeleteDatasetClose()
                            }}
                            autoFocus
                        >
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            <Box className={DatasetCardStyle.baseCard}>
                {dataset.type == 'raster' && (
                    <Box className={DatasetCardStyle.typeIcon}>
                        <img
                            src={raster as string}
                            style={{ width: '16px', height: '16px' }}
                        />
                    </Box>
                )}
                {dataset.type == 'vector' && (
                    <Box
                        className={DatasetCardStyle.typeIcon}
                        backgroundColor={backgroundColor}
                    />
                )}
                <Stack width={'100%'}>
                    <Box className={DatasetCardStyle.cardLine}>
                        <ListItemText
                            primary={dataset.name}
                            secondary={secondaryText}
                            primaryTypographyProps={{ variant: 'h6' }}
                            secondaryTypographyProps={{ variant: 'body2' }}
                            className={DatasetCardStyle.cardText}
                            style={{ wordBreak: 'break-all' }}
                        />{' '}
                        <Box className={DatasetCardStyle.buttonBox}>
                            {!showRemoveFromMap && (
                                <Stack direction="row">
                                    <DatasetDeleteButton
                                        onClick={(e: React.MouseEvent) => {
                                            e.stopPropagation()
                                            setDeleteDialogOpen(true)
                                            setDatasetToDelete(dataset)
                                        }}
                                        className={DatasetCardStyle.hoverIcon}
                                    />
                                    {PROCESSING_STATES.includes(
                                        dataset.status
                                    ) && (
                                        <Tooltip title={'Processing'}>
                                            <CircularProgress
                                                size={'20px'}
                                                sx={{
                                                    mt: '9px',
                                                    mr: '17px',
                                                    ml: '4px',
                                                }}
                                                color="primary"
                                            />
                                        </Tooltip>
                                    )}
                                    {dataset.status === 'processing_failed' && (
                                        <Tooltip title={'Processing failed'}>
                                            <Warning
                                                sx={{
                                                    mt: '9px',
                                                    mr: '17px',
                                                    ml: '4px',
                                                }}
                                                color="error"
                                            />
                                        </Tooltip>
                                    )}
                                </Stack>
                            )}
                            {showRemoveFromMap && (
                                <Tooltip title={'Remove from this map'}>
                                    <IconButton
                                        onClick={() =>
                                            setRemoveFromMapDialogOpen(true)
                                        }
                                        className={DatasetCardStyle.hoverIcon}
                                    >
                                        <Delete />
                                    </IconButton>
                                </Tooltip>
                            )}

                            {dataset.status === 'ready' && (
                                <>
                                    {dataset.className !=
                                        'EarthEngineDataset' &&
                                        showVizSettings && (
                                            <EditVizButton
                                                datasetVersionId={dataset.id}
                                            />
                                        )}
                                    {
                                        // same button for earth engine, but with a menu to select the viz instead
                                        dataset.className ==
                                            'EarthEngineDataset' &&
                                            showVizSettings && (
                                                <>
                                                    <IconButton
                                                        id={
                                                            'viz-select-' +
                                                            dataset.id
                                                        }
                                                        onClick={() =>
                                                            setVisualizationSelectionMenuOpen(
                                                                true
                                                            )
                                                        }
                                                    >
                                                        <ColorLensOutlinedIcon
                                                            className={
                                                                DatasetCardStyle.hoverIcon
                                                            }
                                                        />
                                                    </IconButton>
                                                    <Menu
                                                        anchorEl={document.getElementById(
                                                            'viz-select-' +
                                                                dataset.id
                                                        )}
                                                        open={
                                                            visualizationSelectionMenuOpen
                                                        }
                                                        onClose={() =>
                                                            setVisualizationSelectionMenuOpen(
                                                                false
                                                            )
                                                        }
                                                    >
                                                        <MenuItem disabled>
                                                            Select Visualization
                                                        </MenuItem>
                                                        {dataset.earthEngineVisualizations.map(
                                                            (viz, index) => (
                                                                <MenuItem
                                                                    key={viz.id}
                                                                    onClick={() => {
                                                                        selectEarthEngineVisualization(
                                                                            dataset.id,
                                                                            index
                                                                        )
                                                                        setVisualizationSelectionMenuOpen(
                                                                            false
                                                                        )
                                                                    }}
                                                                    selected={
                                                                        index ===
                                                                        dataset.selectedEarthEngineVisualizationIndex
                                                                    }
                                                                >
                                                                    {viz.name}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Menu>
                                                </>
                                            )
                                    }

                                    {zoomButton}
                                    {visibilityButton}
                                </>
                            )}
                        </Box>
                    </Box>
                    {hasAnyDimensions && (
                        <Box
                            sx={{
                                marginRight: 'auto',
                                marginLeft: 'auto',
                                width: '100%',
                                marginBottom: '2px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'left',
                            }}
                        >
                            <Divider
                                sx={{ marginBottom: '2x', marginTop: '2px' }}
                            />

                            {hasTimeDimension && (
                                <>
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        spacing={1}
                                    >
                                        {useTimeSlider ? (
                                            <DateSlider dataset={dataset} />
                                        ) : (
                                            <DimensionSelector
                                                dimension={dataset.dimensionInfo.dimensions.find(
                                                    (dim) => dim.name === 'time'
                                                )}
                                                dataset={dataset}
                                            />
                                        )}
                                        <Tooltip
                                            title={
                                                useTimeSlider
                                                    ? 'Switch to dropdown selector'
                                                    : 'Switch to time slider'
                                            }
                                            placement="right"
                                        >
                                            <IconButton
                                                size="small"
                                                onClick={() =>
                                                    setUseTimeSlider(
                                                        !useTimeSlider
                                                    )
                                                }
                                            >
                                                {useTimeSlider ? (
                                                    <ViewListIcon />
                                                ) : (
                                                    <ViewTimelineIcon />
                                                )}
                                            </IconButton>
                                        </Tooltip>
                                    </Stack>
                                </>
                            )}
                            {nonTimeDimensions &&
                                nonTimeDimensions.length > 0 &&
                                nonTimeDimensions.map((dim) => (
                                    <DimensionSelector
                                        key={dim.name}
                                        dimension={dim}
                                        dataset={dataset}
                                    />
                                ))}
                        </Box>
                    )}
                </Stack>
            </Box>
        </>
    )
}

export default DatasetCard
