import { Dataset } from './dataset.ts'

type VectorVizParams = {
    mode: 'fill' | 'outline'
    color: string
    width?: number
}

type PseudocolorRasterVizParams = {
    minMaxesPerBand: { [key: string]: [number, number] }
    band: string
    colorRamp: string
}

type MultibandRasterVizParams = {
    minMaxesPerBand: { [key: string]: [number, number] }
    red: string
    green: string
    blue: string
}

type CategoricalRasterVizParams = {
    band: string
    valueMap: { [key: string]: number | number[] }
    colorMap: string
}

type EarthEngineVizParams = {
    [key: string]: any
}
type EarthEngineVisualization = {
    id: string
    name: string
    earthEngineVizParams: EarthEngineVizParams
}

type VizType =
    | 'continuous_singleband_raster'
    | 'continuous_multiband_raster'
    | 'categorical_raster'
    | 'vector'

type VizParams =
    | VectorVizParams
    | PseudocolorRasterVizParams
    | MultibandRasterVizParams
    | CategoricalRasterVizParams

type Viz = {
    id: string
    vizType: VizType
    vizParams: VizParams
}

const DEFAULT_VECTOR_VIZ_PARAMS: VectorVizParams = {
    mode: 'outline',
    color: '#000000',
    width: 2,
}

const DEFAULT_PSEUDOCOLOR_RASTER_VIZ_PARAMS: PseudocolorRasterVizParams = {
    minMaxesPerBand: {},
    band: undefined,
    colorRamp: 'viridis',
}

const DEFAULT_MULTIBAND_RASTER_VIZ_PARAMS: MultibandRasterVizParams = {
    minMaxesPerBand: {},
    red: undefined,
    green: undefined,
    blue: undefined,
}

const DEFAULT_CATEGORICAL_RASTER_VIZ_PARAMS: CategoricalRasterVizParams = {
    minMaxesPerBand: {},
    valueMap: {},
    colorMap: 'paired',
}

// FIXME: Check whether this is still required
function getDefaultRasterVizParams(
    dataset: Dataset,
    vizType: VizType
): VizParams {
    let vizParams: VizParams
    switch (vizType) {
        case 'continuous_singleband_raster':
            const band = dataset.bands[0]
            vizParams = {
                minMaxesPerBand: dataset.minMaxesPerBand,
                band: band,
                colorRamp: 'viridis',
            }
            return vizParams
        case 'continuous_multiband_raster':
            const redBand = dataset.bands[0]
            const greenBand =
                dataset.bands.length > 1 ? dataset.bands[1] : dataset.bands[0]
            const blueBand =
                dataset.bands.length > 2 ? dataset.bands[2] : dataset.bands[0]
            vizParams = {
                minMaxesPerBand: dataset.minMaxesPerBand,
                red: redBand,
                green: greenBand,
                blue: blueBand,
            }
            return vizParams
    }
}

export type {
    VectorVizParams,
    PseudocolorRasterVizParams,
    MultibandRasterVizParams,
    CategoricalRasterVizParams,
    VizParams,
    VizType,
    Viz,
}

export {
    DEFAULT_VECTOR_VIZ_PARAMS,
    DEFAULT_PSEUDOCOLOR_RASTER_VIZ_PARAMS,
    DEFAULT_MULTIBAND_RASTER_VIZ_PARAMS,
    DEFAULT_CATEGORICAL_RASTER_VIZ_PARAMS,
    getDefaultRasterVizParams,
}
