import React, { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Link as LinkIcon, CheckCircle } from '@mui/icons-material'
import { Button } from '@mui/material'
import { useMapContext } from '../../context/map/mapContext'
import { Dataset } from 'types/dataset'
import { MapRef } from 'react-map-gl'

const encodeBbox = (mapRef: React.RefObject<MapRef>) => {
    if (!mapRef.current) return ''

    const bounds = mapRef.current.getBounds()
    return `${bounds.getWest().toFixed(4)},${bounds.getSouth().toFixed(4)},${bounds.getEast().toFixed(4)},${bounds.getNorth().toFixed(4)}`
}

// Helper to get shortened dataset IDs
const getVisibleDatasetIds = (datasets: Dataset[]) => {
    return datasets
        .filter((d) => d.isVisible)
        .map((d) => d.id.substring(0, 4))
        .join(',')
}

const createMapLink = (
    mapId: string,
    mapRef: React.RefObject<MapRef>,
    visibleDatasets: string
): string => {
    const rootUrl = window.location.origin
    const bbox = encodeBbox(mapRef)

    const params = new URLSearchParams()

    if (visibleDatasets) params.set('visible', visibleDatasets)
    if (bbox) params.set('bbox', bbox)

    const queryString = params.toString()
    return `${rootUrl}/map/${mapId}?${queryString}`
}

const ShareBox: React.FC<{ mapRef: React.MutableRefObject<MapRef> }> = ({
    mapRef,
}) => {
    const [copied, setCopied] = useState(false)
    const { state } = useMapContext()
    const currentMap = state.current.metadata

    const handleCopy = () => {
        setCopied(true)
        setTimeout(() => setCopied(false), 2000)
    }

    const visibleDatasets = state.current
        ? getVisibleDatasetIds(state.current.datasets)
        : ''

    return (
        <CopyToClipboard
            text={createMapLink(currentMap.id, mapRef, visibleDatasets)}
            onCopy={handleCopy}
        >
            <Button
                variant="outlined"
                size="small"
                startIcon={
                    copied ? (
                        <CheckCircle color="success" />
                    ) : (
                        <LinkIcon color="primary" />
                    )
                }
                sx={{
                    borderRadius: '20px',
                    backgroundColor: 'var(--panel-background)',
                    color: 'white',
                    borderColor: 'rgba(230, 246, 231, 0.5)',
                    '&:hover': {
                        borderRadius: 'var(--borderRadius, 20px)',
                        border: '1px solid rgba(230, 246, 231, 0.65)',
                        background: 'rgba(39, 46, 45, 0.7)',
                        backdropFilter: 'blur(8px)',
                    },
                    padding: '8px 16px',
                    transition: 'all 0.3s ease',
                }}
            >
                {copied ? 'LINK COPIED' : 'SHARE MAP'}
            </Button>
        </CopyToClipboard>
    )
}

export default ShareBox
