import { createContext, useContext } from 'react'
import { CommentThread } from '../../types/comments'
import { CommentAction, CommentState } from './commentReducer'
import { LngLat } from 'maplibre-gl'

type CommentContextType = {
    state: CommentState
    dispatch: React.Dispatch<CommentAction>
    selectThread: (threadId: string | null) => void
    addThread: (thread: CommentThread) => void
    updateThread: (thread: CommentThread) => void
    toggleCommentMode: () => void
    setCommentMode: (isCommentMode: boolean) => void
    openNewThread: (location: LngLat) => void
}

export const CommentContext = createContext<CommentContextType>(null)

export const useCommentContext = () => useContext(CommentContext)
