import { CommentThread } from '../../types/comments'
import { LngLat } from 'maplibre-gl'

export type DraftThread = {
    title: string
    comment: string
    location: LngLat
}

export type CommentState = {
    mapId: string | null
    selectedThreadId: string | null
    threads: CommentThread[]
    isCommentMode: boolean
    draftThread: DraftThread | null
}

export const initialCommentState: CommentState = {
    mapId: null,
    selectedThreadId: null,
    threads: [],
    isCommentMode: false,
    draftThread: null,
}

export type CommentAction =
    | { type: 'SET_MAP_ID'; mapId: string | null }
    | { type: 'SET_SELECTED_THREAD'; threadId: string | null }
    | { type: 'SET_THREADS'; threads: CommentThread[] }
    | { type: 'ADD_THREAD'; thread: CommentThread }
    | { type: 'ADD_COMMENT'; comment: Comment }
    | { type: 'UPDATE_THREAD'; thread: CommentThread }
    | { type: 'UPDATE_COMMENT'; comment: Comment }
    | { type: 'SET_COMMENT_MODE'; isCommentMode: boolean }
    | { type: 'TOGGLE_COMMENT_MODE' }
    | { type: 'SET_DRAFT_THREAD'; draft: DraftThread | null }
    | { type: 'SET_DRAFT_TITLE'; draft_title: string }
    | { type: 'SET_DRAFT_COMMENT'; draft_comment: string }
export const commentReducer = (
    state: CommentState,
    action: CommentAction
): CommentState => {
    switch (action.type) {
        case 'SET_MAP_ID':
            if (state.mapId === action.mapId) {
                return state
            }
            // clear everything
            return {
                ...initialCommentState,
                mapId: action.mapId,
            }
        case 'SET_SELECTED_THREAD':
            if (action.threadId === null) {
                return {
                    ...state,
                    selectedThreadId: null,
                    isCommentMode: false,
                    draftThread: null,
                }
            }
            const index = state.threads.findIndex(
                (thread) => thread.id === action.threadId
            )
            if (index === -1) {
                return state
            }
            return {
                ...state,
                isCommentMode: false,
                draftThread: null,
                selectedThreadId: action.threadId,
            }

        case 'SET_THREADS':
            if (state.selectedThreadId != null) {
                const index = action.threads.findIndex(
                    (thread) => thread.id === state.selectedThreadId
                )
                if (index === -1) {
                    return {
                        ...state,
                        selectedThreadId: null,
                        threads: action.threads,
                    }
                }
            }
            for (const thread of action.threads) {
                thread.comments.sort(
                    (a, b) => a.createdAt.getTime() - b.createdAt.getTime()
                )
            }
            return {
                ...state,
                threads: action.threads,
            }

        case 'ADD_THREAD':
            // when we add a thread, we leave comment mode and select the added thread
            return {
                ...state,
                selectedThreadId: action.thread.id,
                isCommentMode: false,
                draftThread: null,
                threads: [...state.threads, action.thread],
            }

        case 'UPDATE_THREAD':
            return {
                ...state,
                threads: state.threads.map((thread) =>
                    thread.id === action.thread.id ? action.thread : thread
                ),
            }
        case 'ADD_COMMENT':
            return {
                ...state,
                threads: state.threads.map((thread) =>
                    thread.id === action.comment.threadId
                        ? {
                              ...thread,
                              comments: [
                                  ...thread.comments,
                                  action.comment,
                              ].sort(
                                  (a, b) =>
                                      a.createdAt.getTime() -
                                      b.createdAt.getTime()
                              ),
                          }
                        : thread
                ),
            }
        case 'UPDATE_COMMENT':
            return {
                ...state,
                threads: state.threads.map((thread) =>
                    thread.id === action.comment.threadId
                        ? {
                              ...thread,
                              comments: thread.comments
                                  .map((comment) =>
                                      comment.id === action.comment.id
                                          ? action.comment
                                          : comment
                                  )
                                  .sort(
                                      (a, b) =>
                                          a.createdAt.getTime() -
                                          b.createdAt.getTime()
                                  ),
                          }
                        : thread
                ),
            }
        case 'SET_DRAFT_THREAD':
            return {
                ...state,
                selectedThreadId: null,
                draftThread: action.draft,
            }
        case 'SET_DRAFT_TITLE':
            return {
                ...state,
                draftThread: {
                    ...state.draftThread,
                    title: action.draft_title,
                },
            }
        case 'SET_DRAFT_COMMENT':
            return {
                ...state,
                draftThread: {
                    ...state.draftThread,
                    comment: action.draft_comment,
                },
            }
        case 'SET_COMMENT_MODE':
            return {
                ...state,
                draftThread: null,
                selectedThreadId: null,
                isCommentMode: action.isCommentMode,
            }
        case 'TOGGLE_COMMENT_MODE':
            return {
                ...state,
                draftThread: null,
                selectedThreadId: null,
                isCommentMode: !state.isCommentMode,
            }

        default:
            return state
    }
}
