import React, { useEffect, useState } from 'react'
import PixelInfoViewModule from './PixelInfoView.module.css'
import { boundsStringToArray } from '../../utils'
import {
    Box,
    Divider,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import { useMapContext } from '../../context/map/mapContext.ts'
import { getPixelInfo } from '../../api/tiler.ts'
import { Session } from '@supabase/supabase-js'
import { Dataset } from '../../types/dataset.ts'
import { useSupabaseContext } from '../../context/supabase/supabaseContext.ts'
import Lottie from 'react-lottie-player'
import * as loadingSpinner from '../../assets/loadingSpinnner/white-on-transparent.json'
import { Close } from '@mui/icons-material'

type Coords = {
    lat: number
    lng: number
}

type PixelInfo = {
    datasetName: string
    datasetType: string
    json: string
    error?: string | null
}

function VectorPixelTable({ pixelInfo }: { pixelInfo: PixelInfo }) {
    return (
        <TableContainer className={PixelInfoViewModule.table}>
            <Table
                sx={{
                    minWidth: {
                        xs: 150,
                        '@media (min-width:1000px)': {
                            minWidth: 250,
                        },
                    },
                }}
                size="small"
                aria-label="vector pixel info table"
            >
                <TableBody>
                    {Object.entries(pixelInfo.json).map(([key, value]) => (
                        <TableRow
                            key={key}
                            sx={{
                                '&:last-child td, &:last-child th': {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell component="th" scope="row">
                                <Typography
                                    sx={{
                                        fontSize: {
                                            xs: 10,
                                            '@media (min-width:1000px)': {
                                                fontSize: 12,
                                            },
                                        },
                                    }}
                                >
                                    {key}
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography
                                    sx={{
                                        fontSize: {
                                            xs: 10,
                                            '@media (min-width:1000px)': {
                                                fontSize: 12,
                                            },
                                        },
                                    }}
                                >
                                    {String(Object.values(value)[0])}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

function RasterPixelTable({ pixelInfo }: { pixelInfo: PixelInfo }) {
    return (
        <TableContainer className={PixelInfoViewModule.table}>
            <Table
                sx={{
                    minWidth: {
                        xs: 150,
                        '@media (min-width:1000px)': {
                            minWidth: 250,
                        },
                    },
                }}
                size="small"
                aria-label="raster pixel info table"
            >
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography
                                sx={{
                                    fontSize: {
                                        xs: 11,
                                        '@media (min-width:1000px)': {
                                            fontSize: 13,
                                        },
                                    },
                                    fontWeight: 1200,
                                }}
                            >
                                Band
                            </Typography>
                        </TableCell>
                        <TableCell align="right">
                            <Typography
                                sx={{
                                    fontSize: {
                                        xs: 11,
                                        '@media (min-width:1000px)': {
                                            fontSize: 13,
                                        },
                                    },
                                    fontWeight: 1200,
                                }}
                            >
                                Value
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.entries(pixelInfo.json.Band).map(
                        ([index, bandName]) => (
                            <TableRow
                                key={index}
                                sx={{
                                    '&:last-child td, &:last-child th': {
                                        border: 0,
                                    },
                                }}
                            >
                                <TableCell component="th" scope="row">
                                    <Typography
                                        sx={{
                                            fontSize: {
                                                xs: 10,
                                                '@media (min-width:1000px)': {
                                                    fontSize: 12,
                                                },
                                            },
                                        }}
                                    >
                                        {bandName}
                                    </Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography
                                        sx={{
                                            fontSize: {
                                                xs: 10,
                                                '@media (min-width:1000px)': {
                                                    fontSize: 12,
                                                },
                                            },
                                        }}
                                    >
                                        {
                                            Object.values(pixelInfo.json.Value)[
                                                index
                                            ]
                                        }
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

const computePixelInfo = async (
    datasets: Dataset[],
    coords: Coords,
    supabaseSession: Session
) => {
    const visibleDatasets = datasets.filter((dataset) => dataset.isVisible)
    const datasetsContainingPoint = visibleDatasets.filter((dataset) => {
        const extent = dataset.extent
        const [xmin, ymin, xmax, ymax] = boundsStringToArray(extent)
        return (
            // We don't support getting pixel info for non-local datasets for now
            dataset.source != 'local' &&
            coords.lng >= xmin &&
            coords.lng <= xmax &&
            coords.lat >= ymin &&
            coords.lat <= ymax
        )
    })
    if (datasetsContainingPoint.length === 0) {
        return null
    }
    const pixelInfoPromises = datasetsContainingPoint.map((dataset) =>
        getPixelInfo(
            dataset,
            coords.lat,
            coords.lng,
            supabaseSession?.access_token
        )
    )
    return await Promise.all(pixelInfoPromises)
}

const PixelInfoView = ({
    setActiveDetailPanel,
}: {
    setActiveDetailPanel: (
        panel: 'dataset' | 'pixel' | 'comment' | 'newComment'
    ) => void
}) => {
    const { state, dispatch } = useMapContext()
    const supabase = useSupabaseContext()
    const [pixelInfo, setPixelInfo] = useState<PixelInfo[]>([])
    const [loading, setLoading] = useState(false)
    const lngLat = state.clickedLngLat

    useEffect(() => {
        if (lngLat) {
            setLoading(true)
            setPixelInfo([])
        }
    }, [lngLat])

    useEffect(() => {
        if (lngLat) {
            const coords = { lng: lngLat[0], lat: lngLat[1] }
            const datasets = [...state.current.datasets, ...state.catalog]
            computePixelInfo(datasets, coords, supabase.session).then(
                (pixelInfoList) => {
                    setPixelInfo(pixelInfoList)
                    setLoading(false)
                }
            )
        }
    }, [lngLat])

    if (!state.clickedLngLat) {
        return <></>
    }

    return (
        <Paper className={PixelInfoViewModule.pixelInfoView}>
            <Box className={PixelInfoViewModule.header}>
                <Typography variant="h5">Information</Typography>
                <IconButton
                    onClick={() => {
                        setActiveDetailPanel(null)
                        dispatch({
                            type: 'SET_CLICKED_LAT_LNG',
                            lngLat: null,
                        })
                    }}
                >
                    <Close fontSize="large" />
                </IconButton>
            </Box>
            <Divider sx={{ mb: '16px' }} />
            {lngLat && (
                <Typography
                    variant="h5"
                    className={PixelInfoViewModule.content}
                >
                    X, Y: {lngLat[0].toFixed(6)}, {lngLat[1].toFixed(6)}
                </Typography>
            )}
            {(pixelInfo == null || (pixelInfo.length === 0 && !loading)) && (
                <Typography
                    variant="body1"
                    className={PixelInfoViewModule.content}
                >
                    Click a feature or pixel within the bounds of a visible
                    dataset to view pixel details. At the moment, we don't
                    support pixel details for local datasets.
                </Typography>
            )}
            {loading && (
                <>
                    <Typography
                        sx={{
                            mt: '10px',
                        }}
                        variant="body1"
                        className={PixelInfoViewModule.content}
                    >
                        Loading pixel info ...
                    </Typography>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Lottie
                            loop
                            animationData={loadingSpinner}
                            play
                            style={{
                                width: 70,
                                height: 70,
                            }}
                        />
                    </Box>
                </>
            )}
            {pixelInfo && pixelInfo.length != 0 && (
                <Box className={PixelInfoViewModule.infoTables}>
                    {pixelInfo.map((dsetInfo, index) => (
                        <div key={dsetInfo.datasetName}>
                            <Typography
                                sx={{
                                    fontSize: {
                                        xs: '12px',
                                        '@media (min-width:1000px)': {
                                            fontSize: '14px',
                                        },
                                    },
                                }}
                                fontWeight={800}
                                align="left"
                                marginTop={2}
                                marginBottom={1}
                            >
                                {dsetInfo.datasetName}
                            </Typography>
                            {dsetInfo.error && (
                                <Typography
                                    variant="body1"
                                    className={PixelInfoViewModule.content}
                                >
                                    {dsetInfo.error}
                                </Typography>
                            )}
                            {!dsetInfo.json ||
                                (Object.keys(dsetInfo.json) == 0 && (
                                    <Typography
                                        variant="body1"
                                        className={PixelInfoViewModule.content}
                                    >
                                        No data available
                                    </Typography>
                                )) ||
                                (dsetInfo.datasetType === 'raster' ? (
                                    <RasterPixelTable pixelInfo={dsetInfo} />
                                ) : (
                                    <VectorPixelTable pixelInfo={dsetInfo} />
                                ))}
                        </div>
                    ))}
                </Box>
            )}
        </Paper>
    )
}

export default PixelInfoView
export type { PixelInfo }
