import { SupabaseClient } from '@supabase/supabase-js'

async function earthscaleCanRead(
    url: string,
    accessToken: string
): Promise<boolean> {
    const backendUrl = import.meta.env.EARTHSCALE_BACKEND_URL as string
    const strippedBackendUrl = backendUrl.replace(/\/+$/, '')

    const response = await fetch(
        `${strippedBackendUrl}/ingest/has-read-access?path=${url}`,
        {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        }
    )
    return await response.json()
}

export { earthscaleCanRead }
