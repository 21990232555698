import * as React from 'react'
import Box from '@mui/material/Box'
import { Map as MapGLMap, Source } from 'react-map-gl/maplibre'
import { Auth } from '@supabase/auth-ui-react'
import MapboxSatellite from '../../components/MapView/MapboxSatellite'
import { ThemeSupa } from '@supabase/auth-ui-shared'
import logo_light from '../../assets/earthscale-logo-light.svg'
import LoginStyles from './Login.module.css'
import { Divider, Paper, useTheme } from '@mui/material'
import CacheManager from '../../context/cache'
const startLocations = [
    {
        latitude: 38.6559,
        longitude: -101.9236,
        zoom: 12,
    },
    {
        latitude: 35.615,
        longitude: 139.653,
        zoom: 12,
    },
    {
        latitude: 46.44,
        longitude: 7.747,
        zoom: 12,
    },
    {
        latitude: 25.636,
        longitude: 10.077,
        zoom: 12,
    },
    {
        latitude: 64.46217212812809,
        longitude: 10.74625177968295,
        zoom: 12,
    },
    {
        latitude: 3.7440868431601615,
        longitude: -60.2883514844536,
        zoom: 12,
    },
    {
        latitude: 22.559,
        longitude: 32.314,
        zoom: 12,
    },
]

const randomStartLocation =
    startLocations[Math.floor(Math.random() * startLocations.length)]

function wrapLongitude(longitude: number): number {
    if (longitude > 180) {
        return longitude - 360
    }
    if (longitude < -180) {
        return longitude + 360
    }
    return longitude
}

function wrapLatitude(latitude: number): number {
    if (latitude > 90) {
        return latitude - 180
    }
    if (latitude < -90) {
        return latitude + 180
    }
    return latitude
}

function Login({ supabaseClient, mapRef }) {
    // Clear cache on first render
    React.useEffect(() => {
        CacheManager.invalidateCache()
    }, [])

    const theme = useTheme()
    const backendUrl = import.meta.env.EARTHSCALE_BACKEND_URL

    const customTheme = {
        default: {
            colors: {
                brand: 'hsl(153 60.0% 53.0%)',
                brandAccent: 'hsl(154 54.8% 45.1%)',
                brandButtonText: 'white',
                // ..
            },
        },
    }

    const providers = ['google']

    // Move login map
    const distancePerSecond = 0.001
    React.useEffect(() => {
        const interval = setInterval(() => {
            if (mapRef.current === null) {
                return
            }
            const center = mapRef.current.getCenter()
            center.lng = wrapLongitude(center.lng + distancePerSecond)
            center.lat = wrapLatitude(center.lat + distancePerSecond)
            mapRef.current.easeTo({
                center,
                essential: true,
                duration: 1000,
                easing: (n) => n,
            })
        }, 1000)
        return () => clearInterval(interval)
    }, [mapRef])

    return (
        <Box className={LoginStyles.loginContainer}>
            <MapGLMap
                initialViewState={randomStartLocation}
                ref={mapRef}
                interactive={false}
                attributionControl={true}
            >
                <MapboxSatellite />
            </MapGLMap>

            <Paper className={LoginStyles.authBox}>
                <img
                    src={logo_light as string}
                    alt="Logo"
                    className={LoginStyles.logo}
                />
                <Divider
                    sx={{ width: '100%', color: theme.palette.primary.light }}
                />
                <div className={LoginStyles.supabaseAuth}>
                    <Auth
                        supabaseClient={supabaseClient}
                        providers={providers}
                        localization={{
                            variables: {
                                sign_in: {
                                    button_label: 'SIGN IN',
                                },
                            },
                        }}
                        appearance={{
                            theme: ThemeSupa,
                            variables: {
                                // Variables here: https://github.com/supabase-community/auth-ui/blob/main/packages/shared/src/theming/Themes.ts
                                default: {
                                    colors: {
                                        brand: theme.palette.primary.main,
                                        brandAccent: '#D5E1D6',
                                        brandButtonText:
                                            theme.palette.primary.contrastText,
                                        inputText: theme.palette.primary.main,
                                        messageTextDanger:
                                            theme.palette.warning,
                                        messageBackgroundDanger: 'transparent',
                                        defaultButtonText:
                                            theme.palette.primary.main,
                                        defaultButtonBackground: 'transparent',
                                        defaultButtonBackgroundHover:
                                            '#FFFFFF20',
                                        dividerBackground:
                                            theme.palette.divider,
                                    },
                                    fonts: {
                                        bodyFontFamily:
                                            theme.typography.body1.fontFamily,
                                        buttonFontFamily:
                                            theme.typography.h6.fontFamily,
                                        inputFontFamily:
                                            theme.typography.body1.fontFamily,
                                        labelFontFamily:
                                            theme.typography.body1.fontFamily,
                                    },
                                    fontSizes: {
                                        baseButtonSize: '15px,',
                                    },
                                    space: {
                                        inputPadding: '16px',
                                        buttonPadding: '16px',
                                    },
                                    radii: {
                                        borderRadiusButton: '4px',
                                        buttonBorderRadius: '4px',
                                        inputBorderRadius: '4px',
                                    },
                                },
                            },
                        }}
                        view={'sign_in'}
                        showLinks={false}
                    />
                </div>
            </Paper>
        </Box>
    )
}

export default Login
