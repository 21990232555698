import React from 'react'
import {
    Alert,
    Button,
    CircularProgress,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Snackbar,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material'
import { Dataset } from '../../types/dataset.ts'
import { ChevronRight, ExpandMore, HelpOutline } from '@mui/icons-material'
import { useSupabaseContext } from '../../context/supabase/supabaseContext.ts'
import Box from '@mui/material/Box'
import { FolderTree } from './GoogleDriveTree.tsx'
import { registerGoogleDriveFolder } from '../../api/googleDrive.ts'

type AddDriveFolderDialogProps = {
    open: boolean
    onClose: () => void
    onSubmit: () => void
    googleDriveShareLink: string
    setGoogleDriveShareLink: (link: string) => void
}

const AddDriveFolderDialog = ({
    open,
    onClose,
    onSubmit,
    googleDriveShareLink,
    setGoogleDriveShareLink,
}) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                component: 'form',
                onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                    event.preventDefault()
                    onSubmit()
                    onClose()
                },
            }}
        >
            <DialogTitle>Connect Google Drive Folder</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please paste the "Share" link of a Google Drive Folder and
                    make sure we have read-access to the folder. We will
                    automatically detect and add all spatial files in the
                    folder. It might take a few minutes for them to show up. An
                    example of a Google Drive Share Link is:
                    https://drive.google.com/drive/folders/1ct3bOASsUVid4anPdQAbagaSQuxttuVk?usp=drive_link
                </DialogContentText>
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="share_link"
                    name="google_drive_share_link"
                    label="Google Drive Folder Share Link"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={googleDriveShareLink}
                    onChange={(e) => setGoogleDriveShareLink(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button type="submit">Connect</Button>
            </DialogActions>
        </Dialog>
    )
}

interface GoogleDriveCatalogProps {
    datasets: Dataset[]
    googleDriveFolders: GoogleDriveFolders[]
    flyToDatasetBounds: (dataset: Dataset) => void
}

export const GoogleDriveCatalog = ({
    datasets,
    googleDriveFolders,
    flyToDatasetBounds,
}: GoogleDriveCatalogProps) => {
    const supabase = useSupabaseContext()

    const [catalogOpen, setCatalogOpen] = React.useState(false)
    const [dialogOpen, setDialogOpen] = React.useState(false)
    const [googleDriveShareLink, setGoogleDriveShareLink] = React.useState('')
    const [fetchingDatasets, setFetchingDatasets] = React.useState(false)
    const [googleDriveAlert, setGoogleDriveAlert] = React.useState('')

    const handleCatalogOpenClick = () => {
        setCatalogOpen(!catalogOpen)
    }

    const handleConnectClick = () => {
        setDialogOpen(true)
    }

    const handleClose = () => {
        setDialogOpen(false)
    }

    const handleGoogleDriveButtonClick = () => {
        if (googleDriveShareLink != '' && !fetchingDatasets) {
            const folderId = googleDriveShareLink.split('/')[5].split('?')[0]
            setFetchingDatasets(true)
            registerGoogleDriveFolder(
                supabase.session.access_token,
                folderId,
                () => {
                    setFetchingDatasets(false)
                },
                (error) => {
                    setFetchingDatasets(false)
                    setGoogleDriveAlert(
                        'Something went wrong whilst trying to index the folder. ' +
                            'Please make sure it is accessible to the Earthscale and try ' +
                            'again.'
                    )
                }
            )
        }
    }

    return (
        <>
            <List disablePadding>
                <ListItem
                    dense
                    disablePadding
                    secondaryAction={
                        <>
                            {fetchingDatasets && (
                                <Tooltip
                                    title={
                                        'Fetching datasets from Google Drive'
                                    }
                                >
                                    <CircularProgress size="15px" />
                                </Tooltip>
                            )}
                            {!fetchingDatasets && (
                                <Stack direction="row" alignItems="center">
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        onClick={handleConnectClick}
                                        sx={{
                                            cursor: 'pointer',
                                            mr: '5px',
                                        }}
                                    >
                                        <u>Connect</u>
                                    </Typography>
                                    <Tooltip title={'Click to learn more'}>
                                        <IconButton
                                            onClick={() => {
                                                window.open(
                                                    'https://earthscale-ai-earthscale-sdk-demo.readthedocs-hosted.com/en/latest/connecting_google_drive/',
                                                    '_blank',
                                                    'noreferrer'
                                                )
                                            }}
                                            color={'primary'}
                                        >
                                            <HelpOutline />
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                            )}
                        </>
                    }
                >
                    <ListItemButton
                        onClick={handleCatalogOpenClick}
                        sx={{ borderRadius: '8px', padding: '8px' }}
                    >
                        <ListItemIcon
                            style={{
                                minWidth: '0px',
                                paddingRight: '6px',
                                paddingBottom: '4px',
                            }}
                        >
                            {catalogOpen ? <ExpandMore /> : <ChevronRight />}
                        </ListItemIcon>
                        <ListItemText primary="Google Drive Folders" />
                    </ListItemButton>
                </ListItem>
                <Collapse in={catalogOpen} timeout="auto" unmountOnExit>
                    {googleDriveFolders.length == 0 && fetchingDatasets && (
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ ml: '30px' }}
                        >
                            We are currently indexing your new Google Drive
                            Folder. Depending on the size, this can take up to a
                            few minutes.
                        </Typography>
                    )}
                    {googleDriveFolders.length > 0 && (
                        <Box sx={{ marginLeft: '10px' }} key={'folder-tree'}>
                            <FolderTree
                                folders={googleDriveFolders}
                                driveDatasets={datasets}
                                flyToDatasetBounds={flyToDatasetBounds}
                            />
                        </Box>
                    )}
                </Collapse>
            </List>
            <AddDriveFolderDialog
                open={dialogOpen}
                onClose={handleClose}
                onSubmit={handleGoogleDriveButtonClick}
                googleDriveShareLink={googleDriveShareLink}
                setGoogleDriveShareLink={setGoogleDriveShareLink}
            />
            <Snackbar
                open={googleDriveAlert != ''}
                autoHideDuration={6000}
                onClose={() => setGoogleDriveAlert('')}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert
                    onClose={() => setGoogleDriveAlert('')}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {googleDriveAlert}
                </Alert>
            </Snackbar>
        </>
    )
}
