import { Dataset } from '../types/dataset.ts'
import { PixelInfo } from '../components/PixelInfoView/PixelInfoView'
import { DEFAULT_DATA_REGION, TILER_DEPLOYMENT_REGIONS } from '../constants.ts'

function formatDate(date: Date): string {
    return date.toISOString().split('T')[0]
}

function getRegionFromDataset(dataset: Dataset): string {
    const environment = import.meta.env.EARTHSCALE_ENVIRONMENT
    // On localhost, we don't have regions
    let region = ''
    // On preview staging, we've only got our default deployment
    if (environment == 'preview' || environment === 'staging') {
        region = DEFAULT_DATA_REGION
    }
    // On production, we check whether we've got a regional deployment that fits, and
    // fall back to the default deployment if not
    if (environment === 'production') {
        const dataRegion = dataset.dataRegion
        const weGotADeployment = TILER_DEPLOYMENT_REGIONS.includes(dataRegion)
        if (weGotADeployment) {
            region = dataRegion
        } else {
            region = DEFAULT_DATA_REGION
        }
    }
    return region
}

function createTileServerURL(dataset: Dataset, user?: { api_keys: string }) {
    const selectedDimensions = Object.entries(dataset.selectedDimensions)
        .map(([dim, index]) => `${dim}=${index}`)
        .join('&')
    const dimensionAddition =
        selectedDimensions.length > 0 ? '&' + selectedDimensions : ''

    let vizId: string | null = null

    if (
        dataset.className == 'EarthEngineDataset' &&
        dataset.earthEngineVisualizations != null &&
        dataset.earthEngineVisualizations.length > 0
    ) {
        // ensure idx is in range
        const selectedEarthEngineVisualizationIdx = Math.min(
            Math.max(dataset.selectedEarthEngineVisualizationIndex, 0),
            dataset.earthEngineVisualizations.length - 1
        )
        vizId =
            dataset.earthEngineVisualizations[
                selectedEarthEngineVisualizationIdx
            ].id
    } else {
        vizId = dataset.vizId
    }

    const vizParamsAddition = vizId ? '&viz_id=' + vizId : ''

    const keyAddition = user ? '&key=' + user.api_keys[0] : ''

    const extension = dataset.type == 'raster' ? 'png' : 'mvt'
    let region = getRegionFromDataset(dataset)
    if (region != '') {
        region = '/' + region
    }
    const url =
        import.meta.env.EARTHSCALE_TILER_URL +
        region +
        '/tiles/{z}/{x}/{y}.' +
        extension +
        '?dataset=' +
        dataset.id +
        dimensionAddition +
        vizParamsAddition +
        keyAddition
    return url
}

async function getPixelInfo(
    dataset: Dataset,
    lat: number,
    lon: number,
    accessToken: string
): Promise<PixelInfo> {
    const dimensions = Object.entries(dataset.selectedDimensions)
        .map(([dim, index]) => `${dim}=${index}`)
        .join('&')
    let region = getRegionFromDataset(dataset)
    if (region != '') {
        region = '/' + region
    }

    const url =
        import.meta.env.EARTHSCALE_TILER_URL +
        region +
        '/tiles/pixel-info?dataset=' +
        dataset.id +
        '&lat=' +
        lat +
        '&lon=' +
        lon +
        '&' +
        dimensions

    const response = await fetch(url, {
        headers: {
            Authorization: accessToken,
        },
    })

    if (!response.ok && response.status !== 204) {
        return {
            datasetName: dataset.name,
            datasetType: dataset.type,
            json: {},
            error: `Failed to fetch pixel info: ${response.status} ${response.statusText}`,
        }
    }

    // if 204, return empty object
    if (response.status === 204) {
        const pixelInfo: PixelInfo = {
            datasetName: dataset.name,
            datasetType: dataset.type,
            json: {},
        }
        return pixelInfo
    }

    const pixelInfoJSON = await response.json()
    const pixelInfo: PixelInfo = {
        datasetName: dataset.name,
        datasetType: dataset.type,
        json: pixelInfoJSON,
    }
    return pixelInfo
}

export { createTileServerURL, getPixelInfo }
